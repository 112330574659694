@font-face {
    font-family: Catamaran-Bold;
    src: url(../fonts/Catamaran-Bold.ttf);
}

@font-face {
    font-family: Catamaran-Medium;
    src: url(../fonts/Catamaran-Medium.ttf);
}

@font-face {
    font-family: Catamaran-Regular;
    src: url(../fonts/Catamaran-Regular.ttf);
}

@font-face {
    font-family: Catamaran-Light;
    src: url(../fonts/Catamaran-Light.ttf);
}

@font-face {
    font-family: Catamaran-SemiBold;
    src: url(../fonts/Catamaran-SemiBold.ttf);
}

@font-face {
    font-family: Catamaran-Bold;
    src: url(../fonts/Catamaran-Bold.ttf);
}

@font-face {
    font-family: Catamaran-Regular;
    src: url(../fonts/Catamaran-Regular.ttf);
}

@font-face {
    font-family: FontAwesome;
    src: url(../fonts/fontawesome-webfont-1.svg), url(../fonts/fontawesome-webfont-1.ttf), url(../fonts/fontawesome-webfont-2.eot), url(../fonts/fontawesome-webfont-1.woff)
}

@font-face {
    font-family: 'fontello';
    src: url(../fonts/fontello.eot?0000);
    src: url(../fonts/fontello.eot?0000#iefix) format('embedded-opentype'), url(../fonts/fontello.woff2?0000) format('woff2'), url(../fonts/fontello.woff?0000) format('woff'), url(../fonts/fontello.ttf?0000) format('truetype'), url(../fonts/fontello.svg?0000#fontello) format('svg');
    font-weight: normal;
    font-style: normal;
}