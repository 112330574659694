// Import branding (fonts, colors, gradients) stylesheet
@import "tv2z-config.scss";


:root {
    --font-bold: 'Catamaran-Bold';
    --font-regular: 'Catamaran-Regular';
    --font-medium: 'Catamaran-Medium';
    --font-light: 'Catamaran-Light';
    --font-semi-bold: 'Catamaran-SemiBold';
    --button-color: rgb(var(--tertiary_color)); /* #D93831; */
    --bg-page: rgb(var(--primary_color)); /* #000000; */
    --bg-color: rgb(var(--primary_color)); /* #1d1d1d; */
    --footer-color: rgb(var(--primary_color));
    --mm-color: rgb(var(--secondary_font_color)); /* #999999;*/
    --card-color: rgb(var(--primary_color));
    --bg-font-color: rgb(var(--primary_font_color));
    --button-font-color: rgb(var(--primary_font_color));
    --footer-font-color: rgb(var(--primary_font_color));
    --mm-font-color: rgb(var(--primary_font_color));
    --card-font-color: rgb(var(--primary_font_color));
    --primary-white: rgb(var(--primary_font_color)); /* #ffffff; */
    --secondary-white: rgb(var(--primary_font_color)); /* #FAFAFB; */
    --tertiary-white: rgb(var(--primary_font_color));
    --red-button-color: rgb(var(--tertiary_color)); /* #D93831; */
    --secondary-red: rgb(var(--tertiary_color)); /* #D93831; */
    --input-color: rgba(var(--primary_font_color), 0.5);
    --input-placeholder-color: rgba(var(--primary_font_color), 0.5);
    --bg-player-btn: rgb(var(--primary_color));
    --bg-btn-color: rgb(var(--tertiary_color)); /* #D93831; */
    --sub-font-color: rgb(var(--primary_font_color)); /* #ffffff;*/
    --card-width: 248px;
    --auth-card-width: 544px;
    --auth-card-height: 380x;
    --card-pad: 0px 20px;
    --max-mobile-width: 750px;
    --card-bg: rgb(var(--card_color)); /* rgb(25,25,25); */ /* #191919; */
    --card-bg-rgba: rgba(var(--primary_color), 0.7);  /* rgba(25,25,25, 0.8); */
    --black-sec: rgb(var(--card_color)); /* #171718;*/
    --black-sec-rgb: var(--card_color); /* #171718;*/
    --page-bg: rgb(var(--primary_color)); /* #121213; */
    --slick-dot: rgb(var(--tertiary_color)); /* #D93831; */
    --view-all-card: rgba(var(--secondary_color), 0.05);
    --churn-btn-height:40px;
    --card-border: rgb(var(--border_color)); /* #4d4d4d;*/
    
    --live-page-height: 602px;

    --header-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.84265) 6.67%, rgba(0, 0, 0, 0.819817) 13.33%, rgba(0, 0, 0, 0.780589) 20%, rgba(0, 0, 0, 0.7247) 26.67%, rgba(0, 0, 0, 0.652991) 33.33%, rgba(0, 0, 0, 0.567899) 40%, rgba(0, 0, 0, 0.473712) 46.67%, rgba(0, 0, 0, 0.376288) 53.33%, rgba(0, 0, 0, 0.282101) 60%, rgba(0, 0, 0, 0.197009) 66.67%, rgba(0, 0, 0, 0.1253) 73.33%, rgba(0, 0, 0, 0.0694109) 80%, rgba(0, 0, 0, 0.0301835) 86.67%, rgba(0, 0, 0, 0.00735012) 93.33%, rgba(0, 0, 0, 0) 100%);
    --details-gradient: linear-gradient(0deg, rgba(5, 5, 5, 0.4), rgba(5, 5, 5, 0.4)), linear-gradient(180deg, rgba(5, 5, 5, 0) 18.98%, var(--bg-color) 100%);
    --home-bg-gradient: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2));
    --slick-prev-gradient: linear-gradient(to right, rgba(18, 18, 19, 0.94) 0%, rgba(18, 18, 19, 0.54) 56%, rgba(18, 18, 19, 0) 100%);
    --color-info: rgb(var(--primary_font_color));
    --box-info-bg:#0D7FE2;
    --content-detail-gradient: linear-gradient( 237deg, rgba(var(--primary_color), 0) 13%, rgba(var(--primary_color), 0.81) 52%, rgba(var(--primary_color), 0.95) 72%);
    --join-bg-color: rgb(var(--tertiary_color)); /* #D93831; */
    --join-border-color: rgb(var(--tertiary_color)); /* #D93831; */
    --border-color: rgb(var(--tertiary_color)); /* #D93831; */
    --error-color: rgb(var(--font_color_error)); /* #DB2B20; */

    --home-banner-gradient-bg1 : linear-gradient(0deg, rgba(var(--primary_color), 0.55), rgba(var(--primary_color), 0.55));
    --home-banner-gradient-bg2 : linear-gradient(0deg, rgba(var(--primary_color),1) 3.8%, rgba(var(--primary_color), 0) 90.6%);

    --landscape-banner-metadata-bg : linear-gradient(90deg, rgba(var(--primary_color),1) 20%, rgba(var(--primary_color), 0) 100%);
    --only-landscape-banner-bg : linear-gradient(90deg, rgba(var(--primary_color),1) 20%, rgba(var(--primary_color), 0) 100%);
    --portrait-banner-metadata-bg : linear-gradient(0.56deg, rgba(var(--primary_color),1) 22.73%, rgba(var(--primary_color), 0.7) 59.45%, rgba(var(--primary_color), 0) 92.52%);


    // Core Variables 
    --tile-media-img-gradient-bg: linear-gradient(179deg, rgba(var(--black-sec-rgb), 0) 58%, rgba(var(--black-sec-rgb), 0.95) 99%);
    --series-gradient-bg: linear-gradient(180.38deg, rgba(5, 5, 5, 0.1) 49.89%, var(--bg-color) 99.77%);
    --series-tile-img-bg: linear-gradient(180.38deg, rgba(5, 5, 5, 0.1) 49.89%, var(--bg-color) 99.77%), linear-gradient(0deg, rgba(31, 31, 31, 0.24), rgba(31, 31, 31, 0.24));
    --banner-slick-arrows-bg: #4D4D4D;
    --banner-slick-arrows-hoverbg: #ffffff;
    --uk-button-bg: #ecf0f1;
    --uk-button-hoverbg: #ddd;
    --form-link-color: rgb(var(--tertiary_color));
    --loader-progress-bg: linear-gradient(90deg, rgba(37, 37, 37, 0), rgba(37, 37, 37, .6), rgba(37, 37, 37, 0));
    --vjs-color: rgb(var(--primary_color));
    --primary-black: rgb(0,0,0); /* #000000; */
    --light-grey: #e1e1e1;
    --grey-primary: rgb(var(--secondary_font_color)); /* #999999;*/
    --grey-secondary: rgb(var(--secondary_font_color)); /* #999999;*/
    --grey-tertiary: rgb(var(--secondary_font_color)); /* #999999;*/
    --help-block-color: rgb(var(--font_color_error)); /* #DB2B20; */
    --blue-btn-color: rgb(var(--primary_color));

    // new variables
   --border-radius: 4px;
   --uk-notification-bg: #7cb342;
   --selected-epg: var(--card-bg);
   --plan-offer-color: #39AC43;
   --social-btn-color: var(--grey-primary);
   --trailer-btn-bg: rgba(var(--secondary_color), 0.2); /*#ffffff33; */



   $app-name: 'tv2z'!global;
   $bg-page: var(--primary_color)!global;
   $selected-epg: var(--card-bg)!global;
}