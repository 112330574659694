:root {

--primary_color: 29,47,70; /* #1D2F46; */
--secondary_color: 255,255,255; /* #ffffff; */
--tertiary_color: 3,170,211; /* #03AAD3; */
--card_color: 9,20,34; /* #091422; */
--primary_font_color: 255,255,255; /* #ffffff; */
--secondary_font_color: 153,153,153; /* #999999; */
--font_color_error: 219,43,32; /* #DB2B20; */
--border_color: 77,77,77; /* #4d4d4d; */

}