html {
    background: none!important;
    overflow-x: hidden;
}

body {
    font-size: 14px;
    background: var(--bg-color)!important;
    font-family: var(--font-regular)!important;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    overflow-anchor: auto !important;
}

.btn {
    background: var(--button-color) !important;
    color: var(--button-font-color) !important;
    // border-radius: var(--border-radius)!important;
    // font-weight: 600 !important;
}
#success-card button{
    border-radius: var(--border-radius)!important;
}
.submit-btn, .watch-action{
    border-radius: var(--border-radius)!important;
}

.text-wrap {
    text-align: center;
    // margin-top: 15%;
    height: 780px;
    display: block;
    margin-left: 0.5%;
    transition: transform 1s;
}

p {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    font-family: var(--font-regular) !important;
}

a {
    outline: 0;
}

.card-bg {
    background: var(--card-color);
}

.condition-checkbox {
    margin-top: 0 !important;
    min-width: 50px;
    height: 20px;
    cursor: pointer;
}

.fullscreenvideo {
  height: 100vh !important;
}

.hidescrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.hidescrollbar::-webkit-scrollbar {
  display: none;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover {
    background: transparent;
    color: var(--mm-font-color);
    font-weight: bold;
    background-color: transparent !important;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:active,
.navbar-default .navbar-toggle:focus {
    background-color: transparent;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: var(--mm-font-color)!important;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
    background: transparent;
    color: var(--mm-font-color);
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
    color: var(--button-color);
}

.navbar-default .navbar-nav>li>a {
    font-size: 20px;
    color: var(--mm-font-color) !important;
    /* font-weight: bold; */
    font-family: var(--font-bold);
    line-height: 30px;
    margin: 0px 15px;
}

.navbar-default .navbar-nav>.active>a {
    font-size: 20px;
    background: transparent;
    font-weight: bold;
    background-color: transparent !important;
    font-weight: 300;
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
    background: transparent;
    color: var(--button-color) !important;
    font-weight: bold;
    background-color: transparent !important;
}

.navbar-nav>li>.dropdown-menu {
    /* width: 143px; */
    /* height: 260px; */
    // background: rgba(0, 0, 0, .8);
    background: rgba($bg-page, .8)!important;
    // border-radius: 28px !important;
    padding: 10px 10px;
    min-width: 175px !important;
}

.navbar-default .navbar-nav>li>a {
    font-size: 100%;
    font-family: var(--font-bold) !important;
    font-weight: 300;
    font-size: 20px;
}

.dropdown-menu li>a {
    color: var(--mm-font-color);
    margin-bottom: 16px;
    font-weight: 550;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    /* color     : var(--primary-white); */
}

.dropdown-menu li>a:hover,
.dropdown-menu li>a:focus {
    text-decoration: underline;
    color: var(--button-color);
}

.dropdown-menu li>a:active {
    outline: none;
}

.nav-profile-menu>li>a:hover,
.nav-profile-menu>li>a:focus {
    text-decoration: underline;
    color: var(--mm-font-color);
}

.more-profile>li>a {
    text-align: center;
    width: auto;
    margin: 12px 6px;
}

.more-profile>li>a:hover {
    font-family: var(--font-bold);
}

.navbar-nav.left-align>li>a:focus,
.navbar-nav.left-align>li>a:hover {
    transform: scale(1.1);
}

.form-header .navbar-brand {
    height: 80px;
}

.form-header .navbar-brand img {
    height: 100px;
    width: 150px;
    margin-left: 32px;
}

.navbar-brand img {
    height: 100px;
    width: 150px;
}

.navbar-brand {
    float: left;
    height: 50px;
    padding: 5px 0px 0px 0px;
    font-size: 18px;
    line-height: 20px;
    width: 135px;
    pointer-events: visible;
}

.navbar-brand1 {
    float: left;
    height: 85px;
    padding: 10px 0px 0px 15px;
    width: 100px;
    margin-top: -21%;
}

.navbar-brand1 img {
    width: 120px;
    margin-top: 18px;
    height: 40px;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover {
    background: transparent;
    color: var(--mm-font-color) !important;
    font-weight: bold;
    background-color: transparent !important;
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
    color: var(--button-color);
}

.navbar-nav>li>a {
    padding: 0px !important;
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
    background: transparent;
    color: var(--button-color) !important;
    font-weight: bold;
    background-color: transparent !important;
}

.navbar-right.right-align>li>a:focus,
.navbar-right.right-align>li>a:hover {
    transform: scale(1.05);
}

.dropdown-menu li>a {
    color: var(--mm-font-color) !important;
    margin-bottom: 16px;
    font-weight: 550;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    display: flex;
    align-items: center;
    /* color     : var(--primary-white); */
    justify-content: center;
    word-break: break-word;
}

.dropdown-menu li>a:hover,
.dropdown-menu li>a:focus {
    text-decoration: underline;
    color: var(--button-color);
}

.dropdown-menu li>a:active {
    outline: none;
}

.scrollable-menu {
    height: auto;
    max-height: 290px;
    overflow-x: hidden;
}

@media screen and (max-width: 1300px) {
    .channel-info {
        left: -42px;
    }
    .scrollable-menu {
        max-height: 200px !important;
    }
}

.scrollable-menu::-webkit-scrollbar {
    width: 29px;
    height: 18px;
}

.scrollable-menu::-webkit-scrollbar-thumb {
    height: 6px;
    border: 13px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 10px;
    background-color: var(--bg-font-color);
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.scrollable-menu::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

.navbar-default {
    pointer-events: none;
    height: 80px;
    margin-bottom: 31px;
    background: var(--header-gradient) !important;
}

a:focus,
a:hover {
    outline: none !important;
    text-decoration: none !important;
}

button:focus {
    outline: none !important;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
    background: none !important;
}

.no-margin {
    margin: 0px !important;
}

.lower-z-index:hover {
    z-index: 90 !important;
}

.tile-media {
    /* width: 323.29px;
    height: 221.5px;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transition: 350ms; */
    overflow: hidden;
    // width: 280px;
    height: 194px;
    // border: .5px solid rgba(255, 255, 255, .2);
    box-sizing: border-box;
    transition: 350ms;
    /* transition-delay       : 100ms; */
    transition-timing-function: cubic-bezier(0.26, 0.57, 0.46, 0.98);
    // filter: saturate(1.3);
}


.video-title {
    position: absolute;
    /* width      : 94%; */
    height: 30px;
    left: 11px;
    right: 10px;
    bottom: 20px;
    font-family: var(--font-bold);
    font-style: normal;
    font-size: 22px;
    line-height: 30px;
    color: var(--sub-font-color);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
}

.video-en-title {
    position: absolute;
    width: 94%;
    height: 23px;
    left: 11px;
    right: 10px;
    bottom: 0px;
    font-family: var(--font-regular);
    font-style: normal;
    font-size: 16px;
    line-height: 27px;
    color: var(--bg-font-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    opacity: 1;
}

.series-tile {
    position: relative;
    display: inline-block;
    /* padding              : 5px; */
    font-size: 20px;
    cursor: pointer;
    -webkit-transition: 450ms all;
    transition: 450ms all;
    -webkit-transform-origin: center;
    transform-origin: center;
    margin-bottom: 0px;
}

.tile-media,
.details-tile-media,
.series-tile-media,
.live-channel-tile-media {
    position: relative;
    text-align: center;
}

.series-tile-media:hover {
    /* transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    z-index: 100; */
    margin: 0px 12px;
    transform: scale(1.1);
    z-index: 100;
    opacity: 1;
    /* border    : .5px solid #006AFF78;
    box-shadow: 0px 0px 50px rgba(220, 24, 10, 0.6), 0px 0px 12px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 0px 40px rgba(255, 255, 255, 0.2));*/
}

.slick-track {
  margin-left: 0px !important;
}

.series-tile-media {
    /* width: 243px;
    height: 339.63px;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transition: 350ms; */
    overflow: hidden;
    width: 243px;
    border: .5px solid var(--primary-white);
    box-sizing: border-box;
    transition: 350ms;
    /* transition-delay       : 100ms; */
    transition-timing-function: cubic-bezier(0.26, 0.57, 0.46, 0.98);
    // filter: saturate(1.3);
}

.series-gradient {
    position: absolute;
    width: 242px;
    /* height    : 337.63px; */
    left: 0px;
    top: 0px;
    bottom: 0px;
    background: var(--series-gradient-bg);
    /* box-shadow: 4px 4px 24px rgba(5, 5, 5, 0.56); */
    box-sizing: border-box;
    /* box-shadow: 4px 4px 24px rgba(5, 5, 5, 0.56);
    border-radius: 2px; */
}

.series-tile-img {
    width: 242px;
    height: 337.63px;
    left: 0px;
    top: 0px;
    background: var(--series-tile-img-bg);
    box-shadow: 4px 4px 24px rgba(5, 5, 5, 0.56);
    border-radius: 2px;
}

.wishlist-description {
    width: 596px;
    margin: auto;
    text-align: center;
    color: var(--bg-font-color);
    font-family: var(--font-regular);
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    align-items: center;
    text-align: center;
    position: relative;
    padding: 100px;
}

.search-zoom {
    width: 91px;
    height: 91px;
    margin: auto;
}


/*Slick video css*/

// .slick-track:hover .tile-media {
//     opacity: 0.6;
// }
// .slick-track:hover .tile-media:hover {
//     opacity: 1;
// }
// .slick-track:hover .series-tile-media {
//     opacity: 0.6;
// }
// .slick-track:hover .series-tile-media:hover {
//     opacity: 1;
// }
// .box:hover .tile-media {
//     opacity: 0.6;
// }
// .box:hover .tile-media:hover {
//     opacity: 1;
// }
// .box:hover .series-tile-media {
//     opacity: 0.6;
// }
// .box:hover .series-tile-media:hover {
//     opacity: 1;
// }
.pipe {
    margin: 0px 15px;
    font-size: 16px;
    color: var(--secondary-white);
    font-family: var(--font-regular);
}

*:focus {
    outline: none !important;
}
.slick-next, .slick-prev {
    font-weight: bold !important;
    padding: 0 !important;
    display: inline-block !important;
    line-height: 7px !important;
    position: absolute !important;
    opacity: 1;
    z-index: 1000 !important;
    top: 79px !important;
    height: 159px !important;
    width: 23px !important;
    transition: 350ms;
    transition-timing-function: cubic-bezier(0.26, 0.57, 0.46, 0.98);
}

.multiple-items {
    .slick-next, .slick-prev {
        font-weight: bold !important;
        padding: 0 !important;
        line-height: 7px !important;
        position: absolute !important;
        opacity: 1;
        z-index: 1000 !important;
        transition: 350ms;
        transition-timing-function: cubic-bezier(0.26, 0.57, 0.46, 0.98);
        position: absolute !important;
        background-repeat: no-repeat;
        width: 200px !important;
        background-size: cover !important;
    }
}
.multiple-items {
    .slick-next {
        background-image: url(../assets/#{$app-name}/img/slider-end-gradient.svg);
    }
    .slick-prev {
        background-image: url(../assets/#{$app-name}/img/slider-start-gradient.svg);
    }
}
.collection-widget-slider {
    .slick-next, .slick-prev {
        top: 79px !important;
        height: 159px !important;
    }
    .slick-next:hover {
        background-image: url(../assets/#{$app-name}/img/slider-end-gradient.svg);
    }
    .slick-prev:hover {
        background-image: url(../assets/#{$app-name}/img/slider-start-gradient.svg);
    }
}
.collection-widget-slider-two {
    .slick-next, .slick-prev {
        top: 142px !important;
        height: 284px !important;
    }
    .slick-next,  .slick-next:hover{
        background-image: url(../assets/#{$app-name}/img/slider-end-gradient.svg);
    }
    .slick-prev, .slick-prev:hover{
        background-image: var(--slick-prev-gradient);
    }
}

.top-in-region-widget-slider {
    .slick-next, .slick-prev {
        top: 162px !important;
        height: 325px !important;
    }
    .slick-next, .slick-next:hover {
        background-image: url(../assets/#{$app-name}/img/top-in-region-right-gradient.svg) !important;
    }
    .slick-prev, .slick-prev:hover {
        background-image: var(--slick-prev-gradient) !important;
    }
}
.group-collection-widget-slider {
    .slick-next, .slick-prev {
        top: 61px !important;
        height: 125px !important;
    }
    .slick-next:hover {
        background-image: url(../assets/#{$app-name}/img/slider-end-gradient.svg);
        .group-collection-tile-media {
            pointer-events: none !important;
        }
    }
    .slick-prev:hover {
        background-image: url(../assets/#{$app-name}/img/slider-start-gradient.svg);
    }
}
.epg-container {
    .prev-btn {
        font-weight: bold !important;
        padding: 0 !important;
        display: inline-block !important;
        line-height: 7px !important;
        position: absolute !important;
        opacity: 1;
        z-index: 1000 !important;
        transition: 350ms;
        transition-timing-function: cubic-bezier(0.26, 0.57, 0.46, 0.98);
        position: absolute !important;
        background-repeat: no-repeat;
        background-size: cover !important;
        width: 144px !important;
        top: 0px !important;
        height: 70px !important;
        border: none;
    }
    .next-btn {
        font-weight: bold !important;
        padding: 0 !important;
        display: inline-block !important;
        line-height: 7px !important;
        position: absolute !important;
        opacity: 1;
        z-index: 1000 !important;
        transition: 350ms;
        transition-timing-function: cubic-bezier(0.26, 0.57, 0.46, 0.98);
        position: absolute !important;
        background-repeat: no-repeat;
        background-size: cover !important;
        width: 144px !important;
        top: 0px !important;
        height: 70px !important;
        right: 0 !important;
        border: none;
    }
    .next-btn {
        background: linear-gradient(270deg, #000000 20%, rgba(0, 0, 0, 0) 100%) !important;
    }
    .prev-btn {
        background: linear-gradient(90deg, #000000 20%, rgba(0, 0, 0, 0) 100%) !important;
    }
    .next-btn:hover {
        background: linear-gradient(270deg, #000000 20%, rgba(0, 0, 0, 0) 100%) !important;
    }
    .prev-btn:hover {
        background: linear-gradient(90deg, #000000 20%, rgba(0, 0, 0, 0) 100%) !important;
    }
    .next-btn:before {
        margin-right: -34px;
        content: url(../assets/#{$app-name}/img/epg-right-arrow.svg) !important;
    }
    .prev-btn:before {
        margin-left: -34px;
        content: url(../assets/#{$app-name}/img/epg-left-arrow.svg) !important;
    }
}
.live-channel-widget-slider {
    .slick-next, .slick-prev {
        top: 163px !important;
        height: 350px !important;
    }
    .slick-next, .slick-next:hover {
        background-image: url(../assets/#{$app-name}/img/live-channel-right-gradient.svg) !important;
    }
    .slick-prev, .slick-prev:hover {
        background-image: var(--slick-prev-gradient) !important;
    }
}

.slick-prev {
    left: 0% !important;
}

.slick-prev.slick-disabled {
    left: 0% !important;
}

.slick-next {
    right: 171.42px !important;
}

.slick-next.slick-disabled {
    right: 131.42px !important;
}

.silck-btn-align {
    .slick-prev,
    .slick-next {
        // top: -105px !important;
    }
}

.slick-prev:hover,
.slick-prev:active,
.slick-prev:focus,
.slick-next:hover,
.slick-next:active,
.slick-next:focus {
    z-index: 1000 !important;
    color: var(--bg-font-color) !important;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    margin-left: -5px !important;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
    display: none !important;
}

.highlight>.slick-slider>.slick-next {
    height: 60px !important;
    top: calc(((100vw - 30px)*.25*.56) + 60px) !important;
    right: 42.4px !important;
}

.highlight>.slick-slider>.slick-prev {
    height: 60px !important;
    top: calc(((100vw - 30px)*.25*.56) + 60px) !important;
    left: 42.4px !important;
}

.series-slider .slick-prev,
.series-slider .slick-next {
    height: 372px !important;
    top: 177px !important;
}

.slick-dots li.slick-active>button {
    background: var(--button-color) !important;
    pointer-events: none;
}

.slick-dots {
    left: -47% !important;
    right: 0;
    bottom: 0 !important;
    height: 50px !important;
    width: auto !important;
}

.slick-dots li {
    height: 12px !important;
    width: 12px !important;
    margin: 0px 8px !important;
}

.slick-dots li button {
    font-size: 0 !important;
    line-height: 0 !important;
    display: block !important;
    padding: 2px !important;
    cursor: pointer !important;
    width: 12px !important;
    height: 12px !important;
    left: 40px !important;
    top: 0px !important;
    background: var(--secondary-white) !important;
    // border-radius: 3px !important;
    transition: 200ms -webkit-transform;
    transition: 200ms transform;
}

// .slick-dots li :hover {
//     transform: scaleX(2) !important;
// }

.series-slider,
.video-slider {
    margin: 16px auto 16px;
}

.box-title {
    margin-bottom: 18px !important;
    width: calc(100% - 130px);
    padding-top: 14px;
}

.box-head {
    color: var(--bg-font-color);
    h3 {
        height: 46px;
        line-height: 46px;
        font-size: 28px;
        color: var(--bg-font-color);
        margin: 0px;
    }
    .tag-line {
        font-size: 16px;
        height: 27px;
        line-height: 27px;
    }
}

.slick-initialized .slick-slide {
    display: block !important;
    padding: 0 20px 0 0 !important;
}

.highlight {
    .slick-track {
        padding: 0px !important;
    }
    .slick-initialized .slick-slide {
        padding: 0px !important;
    }
}

.slick-list {
    overflow: visible !important;
    display:block;
}

.slick-next::before,
.slick-prev::before {
    color: var(--bg-font-color)!important;
    font-size: 40px!important;
    opacity: 1 !important;
}

.slick-next:before {
    content: url(../assets/#{$app-name}/img/slider-right-arrow.svg) !important;
}
.slick-prev:before {
    content: url(../assets/#{$app-name}/img/slider-left-arrow.svg) !important;

}

.multiple-items {
    .slick-next:before {
        margin-right: -48px;
        content: url(../assets/#{$app-name}/img/slider-right-arrow.svg) !important;
    }
    .slick-prev:before {
        margin-left: -48px;
        content: url(../assets/#{$app-name}/img/slider-left-arrow.svg) !important;
    }
}

.banner{
    .slick-next:before {
        content: url(../assets/#{$app-name}/img/banner-r-arrow.svg) !important;
    }
    .slick-prev:before {
        content: url(../assets/#{$app-name}/img/banner-l-arrow.svg) !important;
    }
    .slick-next{
        background: var(--banner-slick-arrows-bg)!important;
        height:60px;
        width:60px!important;
        border-radius:100%;
        display:block;
        padding-left: 6px!important;
    }
    .slick-prev {
        background: var(--banner-slick-arrows-bg)!important;
        height:60px;
        width:60px!important;
        border-radius:100%;
        display:block;
        padding-right: 6px!important;
    }
    .slick-next:hover{
        background: var(--banner-slick-arrows-hoverbg)!important;
    }
    .slick-next:hover::before{
        content: url(../assets/#{$app-name}/img/banner-r-arrow-h.svg) !important;
    }
    .slick-prev:hover {
        background: var(--banner-slick-arrows-hoverbg)!important;
    }
    .slick-prev:hover::before {
        content: url(../assets/#{$app-name}/img/banner-l-arrow-h.svg) !important;
    }
}

.slick-dots>li>button:before {
    display: none;
}

.home-bg-img {
    position: relative;
    min-height: 814px;
    left: 0%;
    right: 0%;
    top: 0px;
}

.section-line {
    background: linear-gradient(90deg, var(--button-color) -4.87%, rgba(220, 24, 10, 0) 100%);
    height: 1px;
    margin-bottom: 16px;
}

.tile {
    position: relative;
    display: inline-block;
    padding: 0px;
    font-size: 20px;
    cursor: pointer;
    -webkit-transition: 450ms all;
    transition: 450ms all;
    -webkit-transform-origin: center;
    transform-origin: center;
}

.video-box-outer {
    height: 100%;
}

.img-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    bottom: 0px;
    // background: linear-gradient(180deg, rgba(5, 5, 5, 0) 27.98%, #050505 85%);
    box-sizing: border-box;
    /* box-shadow: 4px 4px 24px rgba(5, 5, 5, 0.56);
    border-radius: 8px; */
}

.tile-media:hover .img-gradient,
.series-tile-media:hover .series-gradient {}

.tile-img {
    width: 100%;
    height: 158px;
    background: linear-gradient(180.18deg, rgba(5, 5, 5, 0.1) 49.89%, var(--bg-color) 99.77%);
    /* border       : 0.5px solid rgba(255, 255, 255, 0.2); */
    box-sizing: border-box;
    box-shadow: 4px 4px 24px rgba(5, 5, 5, 0.56);
    /* border-radius: 8px; */
    // object-fit: cover;
}

.see-all {
    text-decoration: none;
    display: block;
    color: var(--button-font-color);
    /* margin-right: 48px; */
    font-family: var(--font-regular);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
}

.see-all:hover {
    color: var(--button-color);
}

.section-line {
    background: linear-gradient(90deg, var(--button-color) -4.87%, rgba(220, 24, 10, 0) 100%);
    height: 1px;
    margin-bottom: 16px;
}

.video-box-row {
    -webkit-transition: 450ms -webkit-transform;
    transition: 450ms -webkit-transform;
    transition: 450ms transform;
    transition: 450ms transform, 450ms -webkit-transform;
    font-size: 0;
    white-space: nowrap;
    padding-bottom: 1px;
}

.see-all-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
    margin-left: -5px;
}

.details-video-box-row {
    width: 110%;
}

.tag-card {
    width: auto;
    height: 24px;
    font-family: var(--font-regular);
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    border: 1px solid var(--light-grey); /* #D0D0D0; */
    box-sizing: border-box;
    border-radius: 12px;
    color: var(--bg-font-color);
    text-align: center;
    margin: 0px 10px;
}

.blue-btn {
    border: none;
    font-family: var(--font-bold) !important;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transition: 350ms;
}

.blue-btn:hover {
    background: var(--primary-white);
    color: var(--vjs-color);
    transform: scale(1.05);
    box-shadow: 0px 0px 30px var(--primary-white);
}

.white-btn:hover {
    background: var(--primary-white);
    color: var(--vjs-color);
    transform: scale(1.05);
    box-shadow: 0px 0px 30px var(--primary-white);
}

.hover-btn:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 30px var(--primary-white);
}

.drop-tab {
    /* position: absolute; */
    bottom: 0 !important;
    text-align: center !important;
    left: 25% !important;
    z-index: 30 !important;
}

.nav-tabs {
    border: none !important;
}

.drop-tab .nav-tabs>li.active>a,
.drop-tab .nav-tabs>li.active>a:focus,
.drop-tab .nav-tabs>li.active>a:hover {
    border: none;
    /* border-bottom: 3px solid var(--button-color); */
    background: transparent !important;
    color: var(--bg-font-color) !important;
    z-index: 9 !important;
    height: 20px !important;
    padding: 0px !important;
    margin-right: 14px !important;
    min-width: 120px !important;
    text-align: left !important;
    cursor: pointer !important;
    font-size: 20px !important;
    font-family: var(--font-bold) !important;
    opacity: 1 !important;
}

.drop-tab .single-nav-tabs>li.active>a,
.drop-tab .single-nav-tabs>li.active>a:focus,
.drop-tab .single-nav-tabs>li.active>a:hover {
    color: var(--bg-font-color) !important;
}

.drop-tab .nav-tabs>li>a {
    color: var(--bg-font-color);
    /* font-weight   : 600; */
    font-size: 14px !important;
    /* text-transform: uppercase; */
    border: none !important;
    /* border-bottom : 3px solid transparent; */
    z-index: 9 !important;
    height: 20px !important;
    padding: 0px !important;
    margin-right: 14px !important;
    min-width: 120px !important;
    text-align: left !important;
    cursor: pointer !important;
    font-size: 20px !important;
    font-family: var(--font-bold) !important;
    opacity: .5 !important;
}

.drop-tab .single-nav-tabs>li>a {
    color: var(--bg-font-color) !important;
}

.drop-tab .nav-tabs>li>a:hover,
.drop-tab .nav-tabs>li>a:focus {
    background: transparent !important;
    color: var(--button-color) !important;
    border: none !important;
    /* border-bottom: 3px solid transparent; */
}

.mat-mdc-dialog-container {
    border-radius: 5px !important;
}

.my-full-screen-dialog {
    max-width: 100vw !important;
    box-sizing: border-box;
    overflow-x: hidden;
}

.my-full-screen-season {
    max-width: 40vw !important;
    box-sizing: border-box;
    overflow-x: hidden;
}
.my-full-screen-season .mdc-dialog {
    left: unset !important;
}

.my-full-screen-season .mat-mdc-dialog-container {
    border-radius: 0;
    min-width: 40vw;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    overflow: auto;
    // background-color: var(--page-bg);
    background-color: transparent !important;
    position: absolute;
    top: 0;
    width: auto !important;
    right: 0;
}

.my-full-screen-dialog .mat-mdc-dialog-container {
    border-radius: 0;
    min-width: 100vw;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    overflow: auto;
    // background-color: var(--page-bg);
    background-color: transparent !important;
}

// .cdk-overlay-container {
//     position: fixed;
//     backdrop-filter: blur(5px);
//     opacity: 1;
//     filter: brightness(1);
//     z-index: 9999;
// }

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1046;
    // backdrop-filter: blur(5px);
}
// .cdk-global-overlay-wrapper:has(> .pin-control-container) {
//     //backdrop-filter: blur(5px)!important;
// }

[hidden] {
    display: none!important;
}

.loader-display {
    display: block !important;
}
.account-header {
    color: var(--card-font-color);
    margin: 10px 15px;
    text-align: left;
    font-size: 31px;
    font-family: var(--font-bold);
    font-style: normal;
    font-weight: 500;
    /* border-bottom: 1px solid var(--card-font-color); */
}

.profile-line {
    width: 434px;
    height: 1px;
    left: 0px;
    top: 24px;
    background: linear-gradient(90deg, var(--button-color) 0%, rgba(220, 24, 10, 0) 100%);
}

.account-section-text {
    color: var(--card-font-color);
    font-family: var(--font-bold) !important;
    font-size: 14px;
    margin-bottom: 0px;
    line-height: 21px;
    margin-top: 0px!important;
}

.account-section-text>span {
    font-family: var(--font-regular) !important;
}

.form-navbar {
    border: none;
}

.wdn-text-hidden {
    font-size: 0;
    width: 1px;
    height: 1px;
    display: inline-block;
    overflow: hidden;
    position: absolute!important;
    border: 0!important;
    padding: 0!important;
    margin: 0!important;
    clip: rect(1px, 1px, 1px, 1px);
}

.back {
    position: relative;
    top: 15px;
    cursor: pointer;
}

.uk-modal.uk-open {
    display: flex!important;
    justify-content: center;
    align-items: center;
    z-index: 1047;
    // backdrop-filter: blur(5px);
    // opacity: 1;
    // filter: brightness(1);
}

.uk-modal-footer {
    padding: 0 0 11px 0!important;
}

.uk-modal-dialog {
    padding: 15px 15px 0px;
    border-radius: 5px;
    width: 360px!important;
}

.uk-modal-body {
    padding: 0!important;
}

.uk-modal-title {
    display: block;
    font-size: 22px!important;
    line-height: 20px;
    user-select: none;
    cursor: default;
    padding-bottom: 15px;
}

.uk-modal-body>p {
    margin-top: 0!important;
    margin-bottom: 15px;
    height: auto;
    transition: height 0.4s ease-in;
    display: inline-block;
    width: 100%;
    position: relative;
    // overflow-x: hidden;
    padding-bottom: 15px;
}

.uk-modal-footer {
    border: none!important;
}

.uk-button {
    margin-left: 2px;
    margin-right: 2px;
    padding: 0px 12px!important;
    font-weight: bold!important;
    background-color: var(--uk-button-bg)!important;
    font-size: 14px;
    border-radius: var(--border-radius)!important;
}

.uk-button-default {
    border: none!important;
}

.uk-button-primary {
    background-color: var(--uk-button-bg)!important;
    color: var(--bg-page)!important;
    font-weight: bold;
}

.uk-button:hover {
    background-color: var(--uk-button-hoverbg)!important;
}

.uk-notification-message {
    padding: 0px!important;
    width: 400px!important;
    top: 56px;
    max-width: 100%;
    border-radius: var(--border-radius);
    font-size: 14px!important;
    margin-top: 20px;
    z-index: 10000;
}

.uk-notification-close {
    display: none!important;
}

// .uk-modal-page {
//     overflow: visible!important;
// }
.forgot-text>a,
.terms-text>a,
.signup-text>a {
    font-family: var(--font-bold);
    color: var(--bg-font-color)
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//     -webkit-box-shadow: 0 0 0 30px #353535 inset !important;
//     border: none !important;
//     outline: none;
//     border-radius: 0px !important;
//     color: var(--bg-font-color) !important;
// }
input.login:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
}

.login-form .form-control:focus {
    border-color: var(--input-color);
}

.form-link {
    font-size: 15px;
    color: var(--form-link-color);
}

.form-control {
    border-radius: var(--border-radius)!important;
}

.error-block {
    display: block;
    color: var(--error-color);
    margin: 0;
}

.errorInput {
    color: var(--button-color) !important;
    border: 1px solid var(--error-color) !important;
    -webkit-text-fill-color: var(--button-color) !important;
}


.form-btn {
    background: var(--button-color);
    color: var(--button-font-color) !important;
    width: 100%;
    padding: 8px 30px;
    border: none;
    border-radius: 50px;
    height: 45px;
    margin: 20px 0 0;
    transition: transform 1s;
    outline: none !important;
    font-weight: 600;
}

.form-btn:hover,
.form-btn:focus,
.form-btn:active,
.btn-primary:active {
    background: var(--button-color) !important;
    border: 1px solid var(--button-color);
    outline: none;
    color: var(--button-font-color);
    transform: scale(1.05);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background: var(--button-color);
    border-color: var(--button-color);
    outline: none;
}

.form-link:hover,
.form-link:focus,
.form-link:active {
    color: var(--form-link-color);
}

canvas,
img,
video {
    max-width: none !important;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    opacity: 1 !important;
}

// .uk-modal-page {
//     overflow: visible!important;
// }
button[disabled] {
    cursor: not-allowed!important;
}

.text-hidden {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.text-vertical-hidden {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    overflow: hidden;
}

button {
    outline: none !important;
}

.cdk-global-scrollblock {
    overflow: hidden;
}

.vjs-menu {
    margin-bottom: 22px !important;
}

.video-js .vjs-picture-in-picture-control {
    display: none !important;
}

.mat-mdc-progress-bar {
    width: calc(100% - 60px) !important;
    margin-top: 20px;
    margin-left: 30px;
    height: 4px !important;
}

#episodeProgress.mat-mdc-progress-bar {
    width: 100% !important;
}

.warning-strip {
    display: flex;
    justify-content: center;
    background-color: var(--button-color) !important;
    padding: 16px;
    position: absolute;
    top: 80px;
    width: 100%;
    gap: 16px;
    align-items: flex-start;
    z-index: 1;
    img {
        width: 19.5px;
        height: 19.5px;
    }
    span {
        font-family: var(--font-regular);
        color: var(--bg-font-color);
        font-size: 18px;
        line-height: 20px;
    }
}
.Suspended-strip {
    display: flex;
    justify-content: center;
    background:transparent !important;
    color: var(--join-bg-color);
    border: 1px solid var(--join-border-color);
    margin: 10px;
    padding: 16px;
    position: absolute;
    top: 80px;
    width: 100%;
    gap: 16px;
    font-family: var(--font-regular);
    color: var(--bg-font-color);
    font-size: 18px;
    line-height: 20px;
    align-items: flex-start;
    z-index: 1;
    span {
      padding: 10px;
    }

}


#choose-info {
    .join-now-block {
        margin: -15px 0px 12px 0px!important;
    }
}

#choose-info {
    .join-now-block {
        min-height: 60px;
        border: 1px solid var(--bg-font-color);
        position: relative;
        margin:92px 0px 12px 0px;
        border-radius: var(--border-radius);
        padding: 12px 20px;
        display:flex;
        align-items: center;
        column-gap: 12px;
        justify-content: space-between;
        #cancel-text{
            font-weight:700;
            font-size:18px;
            line-height: 29px;
            color:var(--bg-font-color);
        }
        .join-now-btn {
            border: 1px solid var(--bg-font-color);
            background: transparent;
            height: 36px;
            border-radius: var(--border-radius);
            width: 160px;
            padding: 0px 12px 0px 12px;
            color:var(--bg-font-color);
            font-weight: 700;
            white-space: nowrap;
        }
    }
}

@media only screen and (max-width: 385px) {
    .join-now-block {
        #cancel-text {
            font-size: 14px;
        }
    }
}


.mdc-linear-progress__primary-bar::after {
    background-color: var(--button-color)!important;
}

.mdc-linear-progress__buffer {
    background-color: var(--bg-font-color)!important;
}

.payment-line {
    background: linear-gradient(90deg, var(--button-color) -4.87%, rgba(220, 24, 10, 0) 100%);
    height: 1px;
    margin-bottom: 6px;
}

.vjs-volume-bar.vjs-slider-horizontal .vjs-volume-level:before,
.video-js .vjs-play-progress::after {
    background: var(--button-color) !important;
}

.video-js .vjs-progress-control .vjs-mouse-display:before {
    content: none;
    background: var(--button-color) !important;
}

.video-js .vjs-menu-item:hover {
    background-color: transparent !important;
}

.video-js .item-quality {
    padding: 4px 25px !important;
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus,
.video-js .vjs-big-play-button:active {
    background-color: var(--button-color) !important;
}

.video-js .vjs-play-progress {
    background-color: var(--button-color) !important
}
.video-js .vjs-volume-level{
    background-color: var(--secondary-white) !important;
}
.ima-non-fullscreen:before{
    content: url(../assets/#{$app-name}/img/full-screen.svg);
}
.ima-fullscreen:before{
    content:  url(../assets/#{$app-name}/img/full-screen-close.svg);
}
.ima-fullscreen-div{
    right: 1%;
    top: 20%;
    display: none !important;
    opacity: 0 !important;
}
.vjs-volume-level:before{
    content: url(../assets/#{$app-name}/img/volume-tool-tip.svg);
    position: absolute;
    top:-9px;
    left:-7px;

}

.video-js .vjs-play-progress:before,
.video-js .vjs-progress-control:hover .vjs-play-progress:before {
    background: var(--button-color) !important;
}

.vjs-comment-list {
    list-style-type: none;
    position: relative;
    font-size: 3;
    position: relative;
    -moz-user-select: text !important;
    margin-bottom: 4px;
    color: var(--bg-font-color);
}

.vjs-texttrack-settings {
    display: none !important;
}

.wdn-text-hidden {
    font-size: 0;
    width: 1px;
    height: 1px;
    display: inline-block;
    overflow: hidden;
    position: absolute!important;
    border: 0!important;
    padding: 0!important;
    margin: 0!important;
    clip: rect(1px, 1px, 1px, 1px);
}


/* Theo player theme changes */

.theo-primary-color,
.vjs-selected {
    color: var(--primary-white) !important;
    background-color: transparent !important;
}

.theo-primary-background {
    color: var(--bg-page) !important;
    background-color: var(--button-color) !important;
}

.vjs-loading-spinner {
    border: 7px solid var(--button-color) !important;
    border-top-color: transparent !important;
    z-index:1;
}
.live-video-player{
    .vjs-loading-spinner {
        border: 0px solid var(--button-color) !important;
    }
}

.theo-context-menu {
    display: none
}

.blue-btn:focus,
.white-btn:focus {
    outline: none;
}

.loader.progress {
    background: var(--loader-progress-bg);
}

.vjs-picture-in-picture-control {
    display: none !important;
}

// .vjs-quality-button.vjs-menu-button.vjs-control.vjs-button,
.vjs-context-menu {
    display: none!important;
}

.video-js {
    font-family: var(--font-regular) !important;
}

// .video-js .vjs-background-bar {
//     display: block;
//     position: absolute;
//     height: 100%;
//     width: 100%;
//     background: linear-gradient(180deg, #050505 0%, rgba(5, 5, 5, 0.2) 23.96%, #050505 100%) !important;
//     content: "" !important;
// }
.vjs-cog-menu-button>.vjs-menu-settings>.vjs-menu-div>.vjs-submenu>.vjs-menu-content,
.vjs-submenu.vjs-zoom-menu,
.vjs-audio-button .vjs-menu .vjs-menu-content,
.vjs-subs-caps-button .vjs-menu .vjs-menu-content {
    // background: linear-gradient(243deg, rgba(23, 23, 24, 0.73) 103%, rgba(23, 23, 24, 0.95) 56%, rgba(23, 23, 24, 0.95) 14%) !important;
    color: var(--primary-white);
}

.video-js .vjs-menu-settings .vjs-menu-content .vjs-menu-forward:after {
    filter: invert(1) !important;
}

.bottom-margin {
    margin-bottom: 180px !important;
    bottom: 0px !important;
}
.block-display {
    height: 70px !important;
    position: absolute !important;
    bottom: 0 !important;
}
.fs-bottom {
    bottom: -110px !important;
}
.fs-bg-bottom {
    bottom: 100px !important;
}
.epg-sticky {
    position: fixed !important;
    bottom: unset !important;
    will-change: transform;
}
.vjs-quality-button>.vjs-menu {
    position: fixed;
    top:0;
}
#video-player{
    .vjs-quality-button>.vjs-menu.vjs-lock-showing {
        position: fixed;
        top:0;
    }
}
#trailer-player{
    .vjs-quality-button>.vjs-menu.vjs-lock-showing {
        position: fixed;
        top:0;
    }
}

#toolbarViewerLeft{
    position: relative;
    left: 30%;
}
#toolbarViewerMiddle{
    left: 60% !important;
}

#epg-component {
    #epg-cards{
        // max-width: 100vw;
        width: 100%;
        position: relative;
        z-index: 1043;
        background-color: var(--card-bg-rgba);
        height: 70px;
        // box-shadow: rgba(0, 0, 0, 0.7) 0px 10px 20px 0px;
        transition: all 0.2s ease;
        .epg-slider {
            width: 100%;
            overflow-y: scroll;
            white-space: nowrap;
            background-color: var(--bg-page);
            // background: border-box;
            .epg-item{
                position: relative;
                height: 70px; /* 124px;*/
                display: inline-flex !important;
                align-items: center;
                border: 1px solid transparent;
                justify-content: center;
                pointer-events: all;
                width: 125px;
                .channel-img{
                    height: 45px;
                    display: inline;
                }
            }
            .epg-item:hover {
                background-color:var(--selected-epg);
                border: 1px solid var(--primary-white);
            }
            .selected-epg {
                background-color: var(--selected-epg);
                pointer-events: none;
            }
        }
        .epg-slider::-webkit-scrollbar {
            display: none;
        }
    }
}

@media (max-width:1280px) {
    #epg-component #epg-cards .next-btn, #epg-component #epg-cards .prev-btn {
      display: none !important;
    }
}

.fs-vjs-control-bar {
    width: 100% !important;
}
.fs-vjs-info {
    width:100% !important;
    left:0px !important;
    padding-top: 20px!important;
}
.fs-video {
    top: 0px !important;
    height: 100vh !important;
    width:100vw !important;
}

#live-video-player {
    .video-js {
        height: 522px;
        width:928px;
        position:relative;
        top: 80px;
        margin: 0 auto;
        text-align: center;
        .vjs-tech {
            height: 100%;
            position: relative;
        }
        .vjs-text-track-display{
            height: 570px;
            div, .vjs-text-track-cue {
                bottom: 0% !important;
                width:100%;
                z-index:0!important;
            }
        }
    }
    
    .vjs-subs-caps-button>.vjs-menu>.vjs-menu-content,
    .vjs-audio-button>.vjs-menu>.vjs-menu-content,
    .vjs-quality-button>.vjs-menu>.vjs-menu-content {
        padding: 8rem 4rem 0px 0rem;
    }
    .vjs-quality-button  {
        position: relative; /*initial !important;*/
    }
    .vjs-quality-button.vjs-menu-button.vjs-control.vjs-button>.vjs-control-text {
        // left: 312px !important;
    }
    .video-js .vjs-control-bar {
        // margin-bottom: 181.9px; /*10px; */
        position:absolute;
    }
    .video-js .vjs-control-bar .vjs-progress-control,
    .vjs-time-control {
        display: none;
    };
    .vjs-audio-button, .vjs-subs-caps-button {
        align-items: flex-end;
        display: flex;
    }
    #next-content {
        font-size: 14px;
        cursor: auto;
        width: calc(100% - 84px) !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        direction:unset;
        text-align: right;
        right: 42px;
        bottom: 72px;
        #upcoming-epg{
            font-size: 14px;
            font-family: var(--font-bold);
        }
    }
    .vjs-fullscreen, .fullscreenvideo {
        .vjs-subs-caps-button>.vjs-menu>.vjs-menu-content,
        .vjs-audio-button>.vjs-menu>.vjs-menu-content,
        .vjs-quality-button>.vjs-menu>.vjs-menu-content {
            padding: 8rem 4rem 0px 0rem;
        }
        .vjs-quality-button>.vjs-menu.vjs-lock-showing, .vjs-subs-caps-button>.vjs-menu.vjs-lock-showing {
            min-height: var(--live-page-height);
        }
        .vjs-info {
            #play-content{
                #content-detail{
                    #disc-container{
                        #show-details-wrap {
                            margin-top: 7vh;
                        }
                    }
                }
            }
        }
        .vjs-control-bar {
           margin-bottom: 130px;
           #epg-cards {
                display: block;
                padding: 0 0px 0px 2.4px;
                position: absolute;
                left: 0;
                top: 60px;
                background-color: transparent;
           }
        }
        .vjs-close-fullscreen {
            // bottom: 20px !important;
        }
    }
}

.StripeElement {
    width: 100%;
    border-radius: var(--border-radius) !important;
    border: 1px solid transparent !important;
    padding: 8px 4px;
    background-color: var(--bg-font-color);
    height: 48px;
}
#program-data {
    min-height:400px;
}
.uk-modal-dialog {
    padding: 20px !important;
    width: 544px !important;
    border-radius: 26px !important;
    .uk-modal-title {
        text-align: center;
        font-family: var(--font-bold);
        font-size: 24px !important;
    }
    p {
        font-size: 16px !important;
        line-height: 18px;
        text-align: center;
    }
    .uk-modal-footer {
        padding: 0px !important;
        display: flex;
        justify-content: center;
    }
    .uk-button {
        border-radius: 23px !important;
        margin: 0px 8px !important;
        min-width: 118px;
        font-family: var(--font-bold);
        font-size: 18px;
    }
    .uk-button-primary {
        background: var(--button-color) !important;
        color: var(--bg-font-color) !important;
    }
}

.stripe-update-page {
    .StripeElement {
        background-color: var(--uk-button-hoverbg) !important;
    }
}

#payment-bg,
#card-pay,
#payment-card {
    .StripeElement {
        min-height: 48px;
        width: 100%;
        padding: 4px 0px 0px 12px;
        margin-bottom: 20px;
        border-radius: var(--border-radius) !important;
        background: var(--button-font-color);
    }
    .ElementsApp,
    .ElementsApp .InputElement {
        height: 48px;
    }
}

.d-flex {
    display: flex;
}

.justify-content-space-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

@media only screen and (max-width: 1000px) {
    .StripeElement {
        // width: 298px;
    }
}

.ss-content {
    overflow: hidden!important;
}

.vjs-vplaylist .vjs-vlist .vjs-item p {
    max-height: 35px!important;
}

.vjs-playlist-nav .vjs-next {
    background-image: none!important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
}

.video-js .vjs-progress-control {
    margin: 0 0 0 23px!important;
}

.video-js .vjs-progress-control .vjs-play-progress {
    background-color: var(--button-color);
}

// .video-js .vjs-current-time {
//     position: absolute!important;
//     top: -70px!important;
//     left: 35px;
// }
.vjs-duration.vjs-time-control.vjs-control {
    margin: 0px !important;
}

.vjs-current-time.vjs-time-control.vjs-control::after {
    content: "\00a0 \002F \00a0";
}

.vjs-progress-control.vjs-control {
    position: absolute!important;
    top: -32px !important;
    width: 96.2vw !important;
    left: 0.1% !important;
}

.video-js .vjs-current-time {
    margin-left: none !important;
    left: 0% !important;
}

// .vjs-duration.vjs-time-control.vjs-control {
//     right: 1% !important;
//     z-index: -1;
// }
.vjs-has-started.vjs-user-inactive.vjs-playing:not(.vjs-ad-playing) .vjs-no-pointer {
    visibility: hidden;
}

.video-js .vjs-big-play-button {
    display: block !important;
    background-image: url(../assets/#{$app-name}/img/play.svg) !important;
    background-repeat: no-repeat !important;
    background-color: transparent !important;
    border: none !important;
    // top: 50% !important;
    background-position: center !important;
    height: 86px !important;
    width: 86px !important;
    // left:50% !important;
    // background-size: 40% 50% !important;
    left: calc(50% - 43px);
    top: calc(50% - 43px);
    margin: 0px;
}

.video-js .vjs-big-play-button.vjs-no-pointer {
    display: block !important;
    background-image: url(../assets/#{$app-name}/img/pause.svg) !important;
}

.video-js .vjs-big-play-button:active,
.video-js .vjs-big-play-button:focus,
.video-js:hover .vjs-big-play-button {
    background-color: transparent !important;
}

button.vjs-rewind-control.vjs-rewind-first.vjs-control.vjs-button,
button.vjs-forward-control.vjs-control.vjs-button {
    position: fixed;
    bottom: 0%;
    width: 123px;
    height: fit-content;
    z-index: -4;
    // top: 47.5%;
    top: calc(50% - 20px); /*50%;*/
    height: 44.4px;
    // transform: translateY(-20px);
}

button.vjs-rewind-control.vjs-rewind-first.vjs-control.vjs-button {
    // left: 40% !important;
    left: calc(50% - 106px) !important;
    margin-left:0px!important;
}

button.vjs-forward-control.vjs-control.vjs-button {
    // right: 42% !important;
    right: calc(50% - 106px) !important;
}

button.vjs-rewind-control:hover,
button.vjs-forward-control:hover {
    background-color: transparent!important;
}

.vjs-rewind-control:before {
    content: url(../assets/#{$app-name}/img/rewind-btn.svg);
    display:block;
}

.vjs-forward-control:before {
    content: url(../assets/#{$app-name}/img/forward-btn.svg);
    display:block;
}

button.vjs-play-control.vjs-control.vjs-button.vjs-paused,
button.vjs-play-control.vjs-control.vjs-button.vjs-playing {
    margin-left: 38px !important;
    width: 36px !important;
    height: 36px !important;
    background-color: var(--secondary-white);
    // margin-right: 6px;
}

.video-js .vjs-time-control {
    color: #B8B8B8;
    font-size: 20px;
}

.video-js .vjs-progress-control .vjs-slider {
    height: 4px;
}

.video-js .vjs-progress-control .vjs-slider:before {
    background-color: var(--tertiary-white) !important;
    height: 4px;
}

.video-js .vjs-progress-control .vjs-load-progress,
.vjs-progress-control .vjs-play-progress {
    height: 4px;
}

.video-js .vjs-progress-control .vjs-play-progress:before {
    border-radius: 0px !important;
    transform: rotate(45deg);
    height: 13px;
    width: 13px;
    border: 1px solid var(--input-placeholder-color);
    background-color: var(--primary-white) !important;
    right: -15px !important;
}

button.vjs-play-control.vjs-control.vjs-button.vjs-paused>span.vjs-icon-placeholder,
button.vjs-play-control.vjs-control.vjs-button.vjs-playing>span.vjs-icon-placeholder {
    display: block;
}

.vjs-control-bar {
    justify-content: normal!important;
    margin-bottom: 1rem;
    background-color: transparent!important;
}

button.vjs-mute-control>.vjs-icon-placeholder {
    margin-top: 9px!important;
    margin-right: 7px!important;
}

.vjs-live-control.vjs-control {
    margin-left: 40px;
}

button.vjs-mute-control,
button.vjs-subs-caps-button,
button.vjs-audio-button,
// button.vjs-cog-button,
.vjs-quality-button,
button.vjs-fullscreen-control,
button.vjs-nav-next,
button.all-episodes,
button.start-over,
button.vjs-support {
    display: flex!important;
    // font-size: 20px!important;
    // align-items: center!important;
    // background: var(--bg-player-btn) !important;
    // border-radius: 8px!important;
    // justify-content: center!important;
    // width: fit-content !important;
    // white-space: nowrap !important;
    // padding: 10px !important;
}

// button.vjs-button:hover,
.video-js .vjs-live-display:before {
    background-color: var(--primary-white)!important;
}

.vjs-button>span:before,
.video-js .vjs-play-control .vjs-icon-placeholder:before,
.video-js .vjs-play-control.vjs-paused .vjs-icon-placeholder:before,
.vjs-next:before,
.video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
    font-size: 26px;
    color: var(--button-color);
}

.video-js.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder:before {
    // content: url(../assets/#{$app-name}/img/fullscreen-player.svg);
}

.video-js .vjs-mute-control .vjs-icon-placeholder:before {
    // content: url(../assets/#{$app-name}/img/audio-icon.svg);
}

.vjs-icon-volume-mute:before,
.video-js .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder:before {
    // content: url(../assets/#{$app-name}/img/audio-player.svg);
}

.vjs-current-time.vjs-time-control.vjs-control {
    // width: 90px !important;
    text-align: right !important;
}

span.vjs-duration-display,
span.vjs-current-time-display {
    font-size: 20px;
    color: var(--secondary-white);
}

// span.vjs-current-time-display:after {
//     content: ' /';
//     font-size: 20px;
// }
// .vjs-duration.vjs-time-control.vjs-control::before {
//     content: ' \00a0 \00a0';
// }
button.vjs-fullscreen-control.vjs-control.vjs-button {
    margin-left: auto;
    margin-right: 38px !important;
    position: absolute;
    right: 0;
    align-items: flex-end;
    justify-content: flex-end;
    height:auto!important;
}

.video-js video::-webkit-media-controls,
.vjs-control.vjs-button.vjs-cog-menu-button {
    display: none !important;
}

.video-js video::-webkit-media-controls-panel {
    display: none!important;
    -webkit-appearance: none;
}

.video-js video::--webkit-media-controls {
    display: none !important;
}

.video-js video::--webkit-media-controls-panel {
    display: none!important;
    -webkit-appearance: none;
}

.vjs-480 .vjs-progress-control .vjs-load-progress,
.vjs-480 .vjs-progress-control .vjs-play-progress {
    height: 5px !important;
    top: -2px !important;
}

// .vjs-control-bar>button,
.vjs-control-bar>.vjs-control {
    margin-left: 25px !important;
}

// .video-js .vjs-cog-menu-button,
.video-js .vjs-quality-button,
.video-js .vjs-submenu.vjs-zoom-menu {
    height: 36px !important;
}

// button.vjs-nav-next:before {
//     margin: 1px 5px 0 0;
// }

button.all-episodes.vjs-button.player-button:before {
    background-image: url(../assets/#{$app-name}/img/episodes.svg) !important;
    content: "";
    width: 41.1px !important;
    height: 36.8px !important;
    background-repeat: no-repeat;
}

button.vjs-mute-control.vjs-button.vjs-vol-1:before {
    background-image: url(../assets/#{$app-name}/img/vol-low.svg) !important;
    content: "";
    width: 42px !important;
    height: 40px !important;
    background-repeat: no-repeat;
}

button.vjs-mute-control.vjs-button.vjs-vol-2:before {
    background-image: url(../assets/#{$app-name}/img/vol-med.svg) !important;
    content: "";
    width: 42px !important;
    height: 40px !important;
    background-repeat: no-repeat;
}
button.vjs-mute-control.vjs-button.vjs-vol-3:before {
    background-image: url(../assets/#{$app-name}/img/vol-high.svg) !important;
    content: "";
    width: 42px !important;
    height: 40px !important;
    background-repeat: no-repeat;
}

button.vjs-mute-control.vjs-button.vjs-vol-0:before {
    background-image: url(../assets/#{$app-name}/img/vol-0.svg) !important;
    content: "";
    width: 42px !important;
    height: 40px !important;
    background-repeat: no-repeat;
}

button.start-over.vjs-button.player-button:before {
    background-image: url(../assets/#{$app-name}/img/start-over.svg) !important;
    content: "";
    width: 41.1px !important;
    height: 36.8px !important;
    background-repeat: no-repeat;
}

button.vjs-support.vjs-button.player-button:before {
    background-image: url(../assets/#{$app-name}/img/support.svg) !important;
    content: "";
    width: 41.1px !important;
    height: 36.8px !important;
    background-repeat: no-repeat;
}

button.vjs-support {
    position: absolute !important;
    right: 5.7% !important;
}

button.vjs-nav-next.vjs-button.player-button:before {
    // background-image: url(../assets/#{$app-name}/img/next-episode.svg) !important;
    // content: "";
    // background-size: 28px 40px;
    // width: 28px;
    // height: 40px;
    // background-repeat: no-repeat;
    content: url(../assets/#{$app-name}/img/forward.svg);
}

// button.all-episodes.vjs-button.player-button:hover::before,
// button.vjs-nav-next.vjs-button.player-button:hover::before,
// button.vjs-fullscreen-control.vjs-control.vjs-button:hover::before {
//     filter: invert(1) !important;
// }
button.vjs-subs-caps-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:before {
    background-image: url(../assets/#{$app-name}/img/subtitle.svg)!important;
    content: ""!important;
    width: 36px;
    height: 39px;
    background-repeat: no-repeat;
}

button.vjs-audio-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:before {
    background-image: url(../assets/#{$app-name}/img/player-audio.svg)!important;
    content: ""!important;
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
}

.vjs-quality-button.vjs-menu-button.vjs-control.vjs-button:before {
    background-image: url(../assets/#{$app-name}/img/fhd.svg)!important;
    content: "";
    width: 42px !important;
    height: 40px !important;
    background-repeat: no-repeat;
}

.vjs-quality-button.vjs-menu-button.vjs-control.vjs-button>.quality-span.vjs-no-pointer {
    display: none;
}


.video-js .vjs-menu .vjs-menu-content .vjs-checked:before {
    content: '' !important;
    display: none;
}

li.vjs-menu-item.item-quality.vjs-checked {
    border: 1px solid var(--button-color);
    background-color: rgba(143, 143, 143, 0.1) !important;
}

.vjs-menu-item.item-quality.vjs-checked::before {
    content: url(../assets/#{$app-name}/img/check-mark.svg);
    margin-right: 12px;
    height: 20px;
    width: 26px;
}

// .video-js .vjs-quality-button .vjs-menu {
//     bottom: 20px !important;
// }
.vjs-480 .vjs-quality-button .vjs-menu .vjs-hd-icon{
    color: var(--primary-white) !important;
}
.vjs-quality-button .vjs-menu li:hover .vjs-hd-icon {
    border: solid 1px var(--primary-white);
    background: transparent;
    color: var(--primary-white) !important;
    // font-size: 11px;
    // display: contents;
}
.video-js .vjs-volume-panel {
    margin: 0px 0px -4px 0px;
}
.video-js .vjs-volume-panel .vjs-volume-vertical{
    margin: 3px auto;
}

span.vjs-icon-placeholder {
    display: none;
}

button.vjs-mute-control:after,
button.vjs-subs-caps-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:after,
button.vjs-audio-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:after,
button.vjs-cog-button.vjs-menu-button.vjs-button:after,
button.vjs-fullscreen-control.vjs-control.vjs-button:after,
button.vjs-nav-next:after,
button.all-episodes:after {
    font-weight: 600;
}

button.vjs-mute-control:after {
    content: attr(id);
    width: min-content;
}

button.vjs-subs-caps-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:after {
    content: attr(id);
    width: min-content;
}

button.vjs-audio-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:after {
    content: attr(id);
    width: min-content;
}

button.vjs-cog-button.vjs-menu-button.vjs-button:after {
    content: attr(id);
    width: min-content;
}

button.vjs-fullscreen-control.vjs-control.vjs-button:after {
    content: attr(id);
    width: min-content;
}

// button.vjs-nav-next:after {
//     content: attr(id);
//     width: min-content;
// }
// button.all-episodes:after {
//     content: attr(id);
//     width: min-content;
// }
.vjs-lock-showing:hover {
    .vjs-subs-caps-button.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button {
        background-color: var(--primary-white) !important;
        color: var(--vjs-color) !important;
    }
}

.vjs-volume-vertical:hover {
    .vjs-mute-control.vjs-control.vjs-button.vjs-vol-2 {
        background-color: var(--primary-white) !important;
        color: var(--vjs-color) !important;
    }
}

.vjs-live-control.vjs-control {
    background: var(--red-button-color);
    border-radius: 0px;
    height: 36px;
    padding: 9px 20px;
    display: flex;
    align-items: center;
}

.no-touch .vjs-control-text {
    color: var(--button-font-color) !important;
    font-size: 14px !important;
    font-weight: normal !important;
}

.vjs-live-control>.vjs-live-display {
    font-weight: bold!important;
}

.vjs-live-control>.vjs-live-display>.vjs-control-text {
    display: none !important;
}

.no-touch .video-js .vjs-button:hover,
button.vjs-cog-button.vjs-menu-button.vjs-button:hover,
button.vjs-mute-control:hover,
button.vjs-subs-caps-button.vjs-menu-button.vjs-menu-button-popup:hover,
button.vjs-audio-button.vjs-menu-button.vjs-menu-button-popup:hover,
button.vjs-fullscreen-control.vjs-control.vjs-button:hover,
.all-episodes:hover,
.vjs-nav-next:hover {
    background-color: transparent!important;
    // color: var(--vjs-color)!important;
}

.vjs-play-control.vjs-play-control.vjs-control.vjs-button.vjs-paused:hover,
.vjs-play-control.vjs-control.vjs-button.vjs-playing:hover {
    background-color: var(--primary-white)!important;
}

.vjs-subs-caps-button>.vjs-menu>.vjs-menu-content,
.vjs-audio-button>.vjs-menu>.vjs-menu-content,
.vjs-quality-button>.vjs-menu>.vjs-menu-content {
    padding: 5rem 4rem 0px 0rem;
}

// .vjs-subs-caps-button>.vjs-menu>.vjs-menu-content:before {
//     content: 'Subtitles';
//     font-size: 36px;
//     font-family: var(--font-bold);
//     color: var(--secondary-white);
//     // left: 60%;
//     position: relative;
// }
.vjs-menu-content .subtitle {
    font-size: 36px !important;
    font-family: var(--font-bold);
    color: var(--secondary-white);
    position: relative;
    background: transparent!important;
    cursor: default;
    padding-bottom: 10px !important;
}

.vjs-quality-button>.vjs-menu>.vjs-menu-content:before {
    //content: '';
    font-size: 36px;
    font-family: var(--font-bold);
    color: var(--secondary-white);
    // left: 60%;
    position: relative;
}

.vjs-menu-forward:hover .vjs-menu-forward:after {
    border-left-color: #000!important;
}

.vjs-settings-back:hover {
    color: var(--primary-white)!important;
}

.video-js .item-quality:hover .vjs-hd-icon {
    border: solid 1px transparent;
    background: transparent;
    color: #000!important;
}

.video-js .vjs-zoom-menu .vjs-zoom-reset:hover {
    background-color: transparent;
}

.no-touch .video-js .vjs-menu-settings .vjs-menu-content li:hover .vjs-menu-forward:after {
    border-left-color: #000!important;
}

// .no-touch .video-js .vjs-button:hover button.vjs-cog-button.vjs-menu-button.vjs-button:before,
// .no-touch .video-js .vjs-control:hover button.vjs-mute-control:before,
// .no-touch .video-js .vjs-control:hover button.vjs-subs-caps-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:before,
// .no-touch .video-js .vjs-control:hover button.vjs-audio-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:before,
button.all-episodes:hover button.all-episodes:before {
    filter: invert(1)!important;
}

.video-js .vjs-menu-settings {
    right: -131px;
    bottom: 52px;
}

.vjs-subs-caps-button>.vjs-menu.vjs-lock-showing {
    // right: 0%;
    // width: 550px;
    // bottom: 6px;
    // margin-bottom: -31px !important;
    position: fixed;
    height: 100vh;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 1;
}

.vjs-subs-caps-button>.vjs-menu,
.vjs-audio-button>.vjs-menu,
.vjs-quality-button>.vjs-menu {
    right: 0% !important;
    left:unset!important;
}

.vjs-quality-button>.vjs-menu.vjs-lock-showing {
    right: 0%;
    width: 550px;
    bottom: 6px;
    margin-bottom: -31px !important;
    height: 100vh;
    position: fixed;
    top: 0px;
    bottom: 0px;
}

.vjs-audio-button>.vjs-menu.vjs-lock-showing {
    right: 0%;
    width: 550px;
    bottom: 6px;
    margin-bottom: -31px !important;
    height: 100vh;
    position: fixed;
    top: 0px;
    bottom: 0px;
}
.vjs-menu.vjs-lock-showing {
    width:550px!important;
}

.vjs-subs-caps-button>.vjs-menu,
.vjs-audio-button>.vjs-menu,
.vjs-quality-button>.vjs-menu {
    height: 100vh;
}
.vjs-volume-tooltip{
    display: none;
}

.vjs-subs-caps-button>.vjs-menu>.vjs-menu-content,
.vjs-audio-button>.vjs-menu>.vjs-menu-content,
.vjs-quality-button>.vjs-menu>.vjs-menu-content {
    max-height: 100% !important;
    height: 100%;
    // background-image: linear-gradient(243deg, rgba(var(--black-sec-rgb), 0.73) 103%, rgba(var(--black-sec-rgb), 0.95) 56%, rgba(var(--black-sec-rgb), 0.95) 14%), url(../assets/#{$app-name}/img/caption-bg.svg);
    background: linear-gradient(243deg, rgba(var(--black-sec-rgb), 0.73) 103%, rgba(var(--black-sec-rgb), 0.95) 56%, rgba(var(--black-sec-rgb), 0.95) 14%)!important;
    background-repeat: no-repeat;
    background-size: contain;
    text-align: end;
}

// .vjs-audio-button>.vjs-menu>.vjs-menu-content:before {
//     content: 'Audio';
//     font-size: 36px;
//     font-family: var(--font-bold);
//     margin-left: 15px;
//     color: var(--secondary-white);
// }

.vjs-workinghover:not(.vjs-480) .vjs-menu-button .vjs-menu {
    visibility: hidden;
    transform-origin: unset;
    transition-property: all;
    transition-duration: unset;
    -webkit-transition-property: unset;
    -webkit-transition-duration: unset;
    transition-timing-function: unset;
    // Transform
    // transform-origin: right;
    // transition-property: all;
    // transition-duration: 0.5s;
    // -webkit-transition-property: all;
    // -webkit-transition-duration: 0.5s;
    // transition-timing-function: ease-in-out;
}

// .vjs-audio-button>.vjs-menu.vjs-lock-showing {
//     right: 47px!important;
//     bottom: 26px!important;
// }
ul.vjs-menu-content.vjs-settings-list {
    font-weight: bold;
    padding: 4px;
    font-size: 16px;
}

.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item.vjs-selected,
.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item {
    font-family: var(--font-regular);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.8rem;
    text-transform: capitalize;
    color: var(--secondary-white);
    margin: 10px 0px 10px auto;
    width: fit-content;
    padding: 12px;
}

.vjs-quality-button>.vjs-menu>.vjs-menu-content>.vjs-menu-item {
    font-family: var(--font-regular);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.8rem;
    text-transform: capitalize;
    color: var(--secondary-white);
    margin: 10px 0px 10px auto;
    width: fit-content;
    min-width: 45%;
    padding: 12px !important;
}

.video-js .vjs-quality-button i {
    padding-left: 10px;
    font-size: 18px;
}

.vjs-quality-button .vjs-menu .vjs-hd-icon {
    margin-top: -20px;
    font-family: var(--font-regular);
    padding: 1px 2px;
}

.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item.vjs-selected {
    border: 1px solid var(--button-color);
    background-color: rgba(143, 143, 143, 0.1) !important;
}

.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item.vjs-selected {
    .vjs-menu-item-text::before {
        content: url('/assets/#{$app-name}/img/check-mark.svg');
        margin-right: 12px;
        height: 20px;
        width: 26px;
    }
}

.vjs-cog-menu-button>.vjs-menu-settings>.vjs-menu-div>.vjs-submenu>.vjs-menu-content {
    width: 171px!important;
    position: absolute;
    right: 111px;
    bottom: 1px;
}

.video-js .vjs-menu-settings .vjs-settings-back {
    border-bottom: solid 1px transparent;
}

li.vjs-settings-item.vjs-menu-forward {
    height: 33px!important;
}

.vjs-volume-control.vjs-control.vjs-volume-vertical {
    background: var(--page-bg);
    z-index: 1;
    margin-bottom: 8px;
}
.video-js .vjs-volume-bar.vjs-slider-vertical{
    height: 138px !important;
    background-color: var(--input-color);
}
.video-js .vjs-volume-panel .vjs-volume-vertical{
    width: 36px !important;
}

.video-js .vjs-button {
    width: 42px !important;
    height:auto;
}

.video-js .vjs-volume-level {
    width: 6px!important;
}

.vjs-audio-button {
	height:36px;
}
.vjs-audio-button .vjs-button {
    height:auto!important;
}
.vjs-subs-caps-button  {
	height:39px!important;
    margin-bottom:-1.5px!important;
}



button.vjs-audio-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:focus,
button.vjs-subs-caps-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:focus,
button.vjs-cog-button.vjs-menu-button.vjs-button:focus,
button.vjs-mute-control:focus {
    background: transparent !important;
    color: var(--bg-player-btn) !important;
}

button.vjs-audio-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:focus::before,
button.vjs-subs-caps-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:focus::before,
button.vjs-cog-button.vjs-menu-button.vjs-button:focus::before,
button.vjs-mute-control:focus::before {
    filter: none !important;
}

.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item:hover,
.no-touch .video-js .vjs-menu-settings .vjs-menu-content li:hover {
    background-color: var(--button-color) !important
}

@media screen and (max-width: 1500px) {
    // button.all-episodes:before,
    // button.vjs-fullscreen-control.vjs-control.vjs-button:before,
    // button.vjs-audio-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:before,
    // button.vjs-subs-caps-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:before,
    // button.vjs-cog-button.vjs-menu-button.vjs-button:before {
    //     margin: -2px 6px 0 0 !important;
    // }
    // button.all-episodes:before {
    //     background-size: 18px 30px !important;
    //     width: 18px !important;
    //     height: 30px !important;
    // }
    button.vjs-nav-next:before {
        background-size: 18px 30px !important;
        width: 18px !important;
        height: 30px !important;
    }
    button.vjs-fullscreen-control.vjs-control.vjs-button:before {
        background-size: 18px 30px !important;
        // width: 18px !important;
        height: 30px !important;
        margin:0px!important;
    }
    // button.vjs-audio-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:before {
    //     background-size: 18px 30px !important;
    //     width: 18px !important;
    //     height: 30px !important;
    // }
    // button.vjs-subs-caps-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:before {
    //     background-size: 18px 30px !important;
    //     width: 18px !important;
    //     height: 30px !important;
    // }
    button.vjs-cog-button.vjs-menu-button.vjs-button:before {
        background-size: 18px 30px !important;
        width: 18px !important;
        height: 30px !important;
    }
    button.all-episodes:after {
        font-size: 15px !important;
    }
    button.vjs-mute-control:after {
        font-size: 15px !important;
    }
    button.vjs-audio-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:after {
        font-size: 15px !important;
        width: min-content;
    }
    button.vjs-cog-button.vjs-menu-button.vjs-button:after {
        font-size: 15px !important;
    }
    button.vjs-nav-next:after {
        font-size: 15px !important;
        width: min-content;
    }
    button.vjs-fullscreen-control.vjs-control.vjs-button:after {
        font-size: 15px !important;
        width: min-content;
    }
    button.vjs-subs-caps-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:after {
        font-size: 15px !important;
        width: min-content;
    }
    .vjs-menu {
        margin-bottom: 8px !important;
    }
}

.video-js .vjs-control-bar {
    bottom: 10px;
}

.no-touch .video-js .vjs-menu-settings .vjs-menu-content li:hover .vjs-hd-icon {
    color: #000!important;
}

.no-touch .video-js .vjs-menu-settings .vjs-menu-content li:hover,
.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item:hover {
    background-color: transparent!important;
    color: var(--secondary-white)!important;
}

.vjs-thumbnail-holder {
    bottom: 18px!important;
    border: 3px solid var(--red-button-color);
    left: -100px;
}

.vjs-nav-next {
    position: inherit;
    top: 19%;
}

.next-video-parent {
    position: relative;
    top: -19%;
}

.video-js {
    width: 100%;
    height: 100vh;
}

svg.mat-progress-bar-background.mat-progress-bar-element,
.vjs-480 .vjs-menu-header {
    display: none!important;
}

// .vjs-480 .vjs-duration,
// .vjs-480 .vjs-audio-button,
// .vjs-480 .vjs-progress-slide,
// .vjs-320 .vjs-subs-caps-button,
// .vjs-480 .vjs-progress-slide,
// .vjs-480 .vjs-volume-panel .vjs-volume-vertical {
//     display: block!important;
// }

// .vjs-480.vjs-has-started .vjs-big-play-button {
//     margin: -20px 0 0 -25px!important;
// }

// .vjs-480 .vjs-spacer {
//     display: none!important;
//     -webkit-flex: initial!important;
// }

// .vjs-480 .vjs-menu {
//     position: fixed!important;
//     background-color: transparent!important;
//     width: 13%!important;
//     height: 31%!important;
//     max-height: 31%!important;
//     top: none!important;
//     bottom: 10%!important;
//     left: 43%!important;
//     overflow: inherit!important;
// }

// .vjs-480 .vjs-menu .vjs-menu-content {
//     padding: 0px!important;
//     top: 260%!important;
// }

// .vjs-480 .vjs-menu .vjs-menu-content li {
//     color: var(--primary-white)!important;
// }

// .vjs-480 .vjs-subs-caps-button>.vjs-menu .vjs-menu-content {
//     left: -164%!important;
//     top: 277%!important;
// }

// // .vjs-480 button.vjs-audio-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:before,
// .vjs-480 button.vjs-subs-caps-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:before {
//     content: ""!important;
//     width: 28px!important;
//     display: inline-flex!important;
//     align-items: center!important;
//     position: relative!important;
//     top: -10px!important;
// }

// .vjs-480 button.vjs-subs-caps-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:after,
// .vjs-480 button.vjs-audio-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:after {
//     position: relative !important;
//     top: -4px !important;
// }

// .vjs-480 button.vjs-subs-caps-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:before {
//     background-image: url(../assets/#{$app-name}/img/subtitle.svg)!important;
// }

// // .vjs-480 button.vjs-audio-button.vjs-menu-button.vjs-menu-button-popup.vjs-button:before {
// //     background-image: url(../assets/#{$app-name}/img/audio.svg)!important;
// // }
// .vjs-480 .vjs-progress-control.vjs-control {
//     top: -31px!important;
// }

// .vjs-480 .vjs-progress-holder {
//     background: #444!important;
//     height: 2.5px!important;
// }

// .vjs-480 .vjs-progress-control .vjs-play-progress {
//     height: 2.5px!important;
// }

// .vjs-480 .vjs-big-play-button {
//     width: 150px!important;
//     height: 136px!important;
//     top: 41.5%!important;
// }

.vjs-info {
    font-family: var(--font-regular) !important;
    text-align: center !important;
    top: 24px !important;
    font-size: 28px !important;
    left: 4%;
    color: var(--secondary-white);
}

.vjs-info .vjs-ttl {
    text-align: start;
    width: 80%;
}

#play-content{
    display: none;
}

.vjs-info {
    text-align: start;
}
.live-video{
    .vjs-info {
        top: 0 !important;
        position: absolute;
        width: 928px;
        margin: 0 auto;
        text-align: center;
        left: calc((100% - 928px)/2);
        padding: 0px;
        height: 100%;
        overflow:hidden;
        padding-top: 20px !important;
        img {
            padding: 0;
        }
        #play-content{
            display: block;
        }
        .vjs-text{
            display: none;
        }
    }
    .vjs-next-content{
        position: absolute !important;
        right: 102px;
        color: var(--secondary-white);
        height: 30px !important;
        width: max-content !important;
        direction: rtl;
    }
}


.vjs-info-top {
    background: none !important;
    z-index:1;
}

// .vjs-no-pointer {
//     background: url(../assets/#{$app-name}/img/pause-btn.svg) !important;
// }
.common-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: var(--primary-white);
    text-align: center;
    min-height: 100vh;
    height: 100%;
    font-family: var(--font-regular) !important;
}

.d-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.d-align-center {
    display: flex;
    align-items: center;
}

#signin-pad{
    .form-fields {
        margin: 20px 0px 0px 0px!important;
    }
    .has-input-error{
        border-radius:var(--border-radius);
    }
}

.form-card {
    max-width: var(--auth-card-width);
    margin: 20px auto;
    min-height: var(--auth-card-height);
    padding-bottom: 1px;
    // background-size: cover;
    // background-repeat: no-repeat;
    .d-flex-center {
        justify-content: left;
    }
    .form-fields {
        margin: 30px 0px;
    }
    .input-placeholder {
        // color: var(--button-color);
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        margin:0px;
    }
    .align-input {
        text-align: left;
        padding-bottom: 16px;
        position: relative;
        label{
            font-weight: 400;
            font-size: 14px;
            line-height: 23px;
            margin-bottom:0px;
        }
        .input-box-align {
            position: relative;
            img {
                position: absolute;
                bottom: 15px;
                right: 10px;
            }
        }
        .input-box {
            min-height: 48px;
            width: 100%;
            padding: 0px 12px;
            color: #555 !important;
            background-color: var(--primary-white) !important;
            font-size: 16px;
            border-radius: var(--border-radius)!important;
        }
    }
    button {
        display: flex;
        justify-content: center;
        padding: 8px 50px;
        align-items: center;
        width: 100%;
        border-radius: var(--border-radius)!important;
        margin: 20px auto 20px;
    }
    .card-cred {
        display: flex;
        justify-content: space-between;
        column-gap: 20px;
        .w-45 {
            width: 100%;
        }
    }
    .poweredby {
        display: flex;
        justify-content: center;
        column-gap: 20px;
        margin-top:30px;
    }
    .cvc_value{
        position: relative;
    }

    #free-time {
        font-size: 12px;
    }
     ::placeholder {
        color: var(--input-placeholder-color) !important;
        opacity: 0.7;
        font-size: 16px;
    }
     :-ms-input-placeholder {
        color: var(--input-placeholder-color) !important;
        opacity: 0.7;
        font-size: 16px;
    }
     ::-ms-input-placeholder {
        color: var(--input-placeholder-color) !important;
        opacity: 0.7;
        font-size: 16px;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--primary-white) inset;
    box-shadow: 0 0 0 30px var(--primary-white) inset !important;
    color: var(--input-color);
    -webkit-text-fill-color: #555;
}

input {
    filter: none;
}

.has-error .form-control {
    border: 1px solid var(--error-color) !important
}

.ml-3 {
    margin-left: 3px;
}

@media only screen and (max-width:500px) {
    .uk-modal-dialog {
        min-width: 320px !important;
    }
    .uk-button {
        line-height: 30px !important;
    }
}

.j-center {
    justify-content: center;
}

.j-sb {
    justify-content: space-between;
}

#sub-category-page {
    .slick-prev,
    .slick-next {
        top: -70px !important;
    }
}

// .lower-text {
//     text-transform: lowercase;
// }
.f-12 {
    font-size: 14px;
}

.slick-dotted.slick-slider {
    margin-bottom: 0px !important;
}

// .slick-prev.slick-disabled,
// .slick-next.slick-disabled {
//     display: block !important;
//     pointer-events: none;
//     cursor: not-allowed !important;
//     opacity: .3!important;
// }

@media only screen and (max-width:470px) {
    body {
        --card-width: 240px;
        --card-pad: 0px 20px;
    }
    .form-card {
        margin: 37px 0px;
    }
}

// .rc-anchor-error-msg-container {
//     font-size: 11px !important;
//     top: 7px !important
// }
.clearfix-footer {
    min-height: 40px;
}

.grecaptcha-badge {
    visibility: hidden;
}

.uk-modal-footer {
    .uk-button {
        line-height: 1.4;
        padding: 5px !important;
    }
}

.video-js .vjs-control {
    position: relative;
}

.next-video-parent>.vjs-nav-next {
    position: sticky !important;
}

.next-video-parent ,
.vjs-subs-caps-button,
.vjs-audio-button,
.next-video-parent{
     position: initial !important;
 }


// .vjs-audio-button.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button,
.vjs-load-progress {
    display: none;
}

//videojs.min.css
.vjs-control-bar .vjs-control:hover .vjs-control-text {
    visibility: visible;
    opacity: 1;
    top: -27px;
    height: initial;
    border: 1px solid var(--tertiary-white);
    background: transparent;
    color: var(--primary-white);
}

.vjs-play-control.vjs-control.vjs-button{
    .vjs-control-text {
        left: 0 !important;
        transform: none !important;
    }
}

#live-video-player .video-js .vjs-control-bar {
    // background: linear-gradient(360deg, #000000 50%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(360deg, var(--bg-page) 50%, rgba($bg-page, 0) 100%);
    height: 139px;/*176px;*/
    bottom: 0px;
    align-items: flex-end;
    padding: 0px 0px 30px 0px;
    width: 928px;
    margin: 0 auto;
}

#live-video-player .video-js .vjs-control-bar .vjs-audio-button button.vjs-audio-button,
.video-js .vjs-control-bar .vjs-audio-button button.vjs-audio-button,
.video-js .vjs-control-bar .vjs-subs-caps-button button.vjs-subs-caps-button, .vjs-subs-caps-button {
    position: relative !important;
    // margin-top: 2px;
    align-items:flex-end;
}

.vjs-current-time>.vjs-control-text,
.vjs-duration>.vjs-control-text,
.vjs-rewind-control>.vjs-control-text,
.vjs-forward-control>.vjs-control-text,
.vjs-menu-item>.vjs-control-text {
    display: none !important;
}

.no-touch .video-js .vjs-control-text {
    border: 0;
    margin: 0;
    position: absolute;
    z-index: 2;
    background-color:rgba($bg-page,0.9)!important;
}

.vjs-play-control .vjs-control-text {
    left: 5px;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.vjs-volume-panel .vjs-control-text{
    display: none !important;
}
.no-touch .vjs-control-text {
    display: block;
    border: 0;
    clip: auto;
    top: -10px;
    background-color: var(--primary-white);
    color: #000;
    padding: 0 5px!important;
    line-height: 22px;
    font-size: 11px!important;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    white-space: nowrap;
    -webkit-transition: all .3s;
    transition: all .3s;
    visibility: hidden;
    opacity: 0;
    width: auto;
    z-index: 2;
    text-shadow: none!important;
    font-family: Arial, sans-serif;
}

.vjs-text-track-display {
    height: 100%;
    position: Fixed;
    background-image: linear-gradient(to top, rgba($bg-page, 0.8), rgba(46, 46, 46, 0) 80%);
}
#live-video-player .vjs-text-track-display {
    height: 100%;
    position: Fixed;
    background-image: none !important;
}

#live-video-player {
	button.vjs-fullscreen-control.vjs-control.vjs-button {
		margin-right: 42px !important;
	}
	button.vjs-play-control.vjs-control.vjs-button.vjs-paused {
		margin-left: 42px !important;
	}
	button.vjs-play-control.vjs-control.vjs-button.vjs-playing {
		margin-left: 42px !important;
	}
}

.video-js.vjs-user-inactive.vjs-playing .vjs-text-track-display {
    background: transparent;
    position:fixed;
}

.vjs-text-track-display>div>.vjs-text-track-cue {
    bottom: 11% !important;
    font-size: 1.4em!important;
    div {
        background-color: #000000 !important;
        font-size: 1.4em!important;
    }
}

.vjs-default-skin .vjs-fullscreen-control:before {
    content: url(../assets/#{$app-name}/img/full-screen.svg) !important;
    width: 32px;
    height: 31px;
}

.vjs-default-skin .vjs-close-fullscreen:before {
    content: url(../assets/#{$app-name}/img/full-screen-close.svg) !important;
    width: 32px;
    height: 31px;
}
button.vjs-fullscreen-control.vjs-control.vjs-button .vjs-control-text {
	left: unset!important;
	width: fit-content;
    transform: none;
}
button.vjs-close-fullscreen.vjs-control.vjs-button .vjs-control-text {
	left: unset!important;
	width: fit-content;
    transform: none;
}


.vjs-default-skin.vjs-fullscreen .vjs-fullscreen-control:before {
    content: url(../assets/#{$app-name}/img/full-screen-close.svg) !important;
    width: 32px;
    height: 40px;
}

.vjs-subs-caps-button>.vjs-menu>.vjs-menu-content,
.vjs-audio-button>.vjs-menu>.vjs-menu-content,
.vjs-quality-button>.vjs-menu>.vjs-menu-content {
    min-width: 24vw !important;
    margin-left: auto !important;
}

@media screen and (min-width: 2300px) {
    .vjs-subs-caps-button>.vjs-menu>.vjs-menu-content,
    .vjs-audio-button>.vjs-menu>.vjs-menu-content,
    .vjs-quality-button>.vjs-menu>.vjs-menu-content {
        padding: 8rem 4rem 0px 0rem !important;
    }
    .video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item.vjs-selected,
    .video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item {
        font-size: 2.2rem !important;
    }
}

.vjs-disabled {
    cursor: auto;
    opacity: 0.35;
    pointer-events: none;
}

.next-episode-panel .mat-mdc-dialog-container {
    padding: 0;
    background-color: var(--card-color);
}

.alert-box-dialog,
.mat-mdc-dialog-container {
    padding: 0 !important;
}

.cdk-overlay-connected-position-bounding-box {
    //position mat-tooltip
    top: 8px !important;
}

.mat-tooltip {
    background-color: var(--card-color);
    padding: 6px 8px;
    font-family: var(--font-light);
    font-size: 12px;
    border-radius: 0px !important;
    outline: none;
}

// .vjs-quality-button>.vjs-control-text:after {
//     content: '';
//     display: block;
//     position: absolute;
//     z-index: 1;
//     left: auto;
//     width: 20px;
//     height: 20px;
//     border-radius: 2px;
//     border-left: 17px solid transparent;
//     border-right: 17px solid transparent;
//     border-top: 17px solid red;
// }
.otp-input-class {
    border: hidden !important;
    border-radius: 0px !important;
    outline: none;
    font-size: 21px !important;
    color: transparent;
    font-weight: bold;
    text-shadow: 0 0 0 var(--secondary-red) !important;
    transition: all 0.3s ease;
}

.otp-input-class:focus {
    // border: 2px solid var(--primary-red) !important;
    outline: 2px solid var(--secondary-red) !important;
}

.otp-container-class {
    margin: 30px auto 20px auto;
    // padding: 20px 0px;
    width: max-content;
}
.otp-input-div {
    align-items: center;
    border-radius: var(--border-radius);
    border: 1px solid var(--button-font-color);
    text-align: center;
    color: var(--button-font-color);
    font-family: var(--font-regular);
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: transparent;
}


// end of ng-otp classes
.disabled-text {
    // color: var(--input-color) !important;
}

#parental_enable_toggle {
    .mat-slide-toggle-thumb-container {
        width: 10px;
        height: 10px;
        top: 1.4px;
        left: 1px;
    }
    .mat-slide-toggle-thumb {
        width: 10px;
        height: 10px;
        background-color: var(--secondary-white);
    }
    .mat-slide-toggle-bar {
        width: 29px;
        height: 15px;
        background-color: transparent;
        border-radius: 9px;
        border: 1px solid var(--secondary-white);
    }
    .mat-slide-toggle-ripple {
        display: none;
    }
    &.mat-checked {
        .mat-slide-toggle-bar {
            background-color: var(--button-color) !important;
            border: 1px solid var(--button-color);
        }
        .mat-slide-toggle-thumb-container {
            left: 0px;
        }
    }
}

.telescopic-slider-container{
    .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control{
        left:-14px!important;
        top:-14px!important;
        position:absolute;
    }
}
.telescopic-slider-container {
    position: relative;
    .placeholder-bar {
        width: 100%;
        position: absolute;
        height: 4px;
        top: 6px;
        background-color: var(--primary-white);
    }
    .active-bar {
        height: 7px;
        top: 4.6px;
        position: absolute;
        background-color: var(--button-color);
    }
    .mat-mdc-radio-group {
        text-align: center !important;
        display: flex;
        justify-content: space-evenly;
        .mdc-radio__inner-circle{
            top: -4px !important;
            left: -4px !important;
        }
        .mat-mdc-radio-button {
            position: relative;
            bottom: 2px;
            .mat-radio-ripple {
                display: none;
                .mat-ripple-element{
                    display: none !important;
                }
            }
        }
        .mdc-form-field  {
            .mdc-radio {
                width: 12px;
                height: 12px;
                padding: 0px !important;
                .mdc-radio__outer-circle  {
                    height: 12px;
                    width: 12px;
                    background-color: var(--primary-white);
                    border: 1px solid var(--input-color) !important;
                }
                .mdc-radio__inner-circle {
                    height: 12px;
                    width: 12px;
                }
            }
        }
        .mdc-label {    padding-left: 0px;
            bottom: 35px;
            width: 102px;
            font-family: var(--font-regular);
            font-size: 12px;
            text-align: center;
            position: absolute;
            color: var(--mm-font-color);
            white-space: initial;
            line-height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: auto;
            font-weight: 500;
        }
    }
    .mat-radio-disabled {
        filter: brightness(0.7);
        .mdc-form-field  {
            filter: brightness(0.9);
        }
    }
    .cdk-focused.cdk-mouse-focused {
        .mat-ripple-element{
            display: none !important;
        }
    }
}
.disabled-parent-button{
    .mat-mdc-radio-group{
        .mat-mdc-radio-checked{
            .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
                background-color: var(--primary-white) !important;
                border-color: #000;
                opacity: 0.38;
            }
        }
        .mdc-radio .mdc-radio__native-control:disabled+.mdc-radio__background .mdc-radio__inner-circle {
            background-color: var(--primary-white) !important;
            border-color: #000;
            opacity: 0.38;
        }
        .mdc-radio__outer-circle {
            opacity: 0.38;
        }
    }
}
.enable-parent-button{
    .mat-mdc-radio-group{
        .mat-mdc-radio-checked{
            .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
                transform: scale(0.45) !important;
                border-color: var(--button-color) !important;
            }
        }
    }
}
.mdc-switch__track{
    height: 15px !important;
    width: 29px !important;
    border: 1px solid var(--primary-white);
}
.mdc-switch__handle{
    width: 10px !important;
    height: 10px !important;
    left: 2px !important;
}
.mdc-switch:enabled .mdc-switch__track::before{
    background: var(--card-bg) !important;
}
.mdc-switch:enabled .mdc-switch__track::after {
    background: var(--button-color) !important;
}
.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
    background: none !important;
}
.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after{
    background: none !important;
}
.pin-control-container {
    .mat-mdc-dialog-container {
        padding: 0px !important;
        border-radius: 0 !important;
        width: 432px !important;
        height: 496px !important;
        color: var(--card-font-color);
    }
}

.parent-pin-input {
    font-size: 18px !important;
    border: hidden !important;
    border-radius: 0px !important;
    outline: none;
    color: var(--primary-black) !important;
    transition: all 0.3s ease;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.otp-pin-input {
    font-size: 18px !important;
    border: hidden !important;
    border-radius: 0px !important;
    outline: none;
    color: var(--primary-black) !important;
    transition: all 0.3s ease;
    font-family: var(--font-medium);
    font-style: normal;
    font-weight: normal;
    speak: none;
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.otp-input-error{
    color: var(--error-color) !important;
    border: 2px solid var(--error-color) !important;
}

// .parent-pin-input:focus {
//     outline: 2px solid var(--secondary-red) !important;
// }
.voucher-label {
    font-size: 12px;
    color: var(--secondary-white);
    opacity: 0.7;
}
.cdk-overlay-connected-position-bounding-box {
    //position mat-tooltip
    top: 8px !important;
}

.mat-tooltip {
    background-color: var(--card-color);
    padding: 6px 8px;
    font-family: var(--font-light);
    font-size: 12px;
    border-radius: 0px !important;
    outline: none;
}

// .vjs-quality-button>.vjs-control-text:after {
//     content: '';
//     display: block;
//     position: absolute;
//     z-index: 1;
//     left: auto;
//     width: 20px;
//     height: 20px;
//     border-radius: 2px;
//     border-left: 17px solid transparent;
//     border-right: 17px solid transparent;
//     border-top: 17px solid red;
// }


.uk-modal-dialog .align-input p {
    text-align: left;
}
.uk-modal-dialog .input-placeholder {
    padding: 0px 0px 5px 5px;
    font-size: 11px !important;
    margin: 0;
}
.cvvtooltip .cvvtext {
    visibility: hidden;
    width: 218px;
    background-color:var(--card-bg);
    color: var(--secondary-white);
    z-index: 1;
    bottom: 100%;
    font-family: var(--font-light);
    margin-left: -208px;
    text-align: left;
    line-height: 1.4;
    font-weight: 300;
    border-radius: 0px;
    padding: 5px 8px;
    position: absolute;
    margin-bottom: 7px;
    border: 1px solid var(--tertiary-white);
    font-size:10px;
}
.cvvtooltip {
    position: absolute;
    display: inline-block;
    margin-top: -54px;
    z-index: 99;
    right: 15px;
}
.cvvtooltip .cvvtext:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 96%;
    margin-left: -10px;
    border-width: 4px;
    border-style: solid;
    border-color:var(--card-bg) transparent transparent transparent;
}
.cvvtooltip:hover .cvvtext {
    visibility: visible;
}
.mdc-linear-progress__primary-bar::after{
    height: 130% !important;
    top:-15% !important;
    width: 102%!important;
    left:-2% !important;
}

.no-pointer {
    pointer-events: none;
}

#not-available, #channel-not-available{
    background-color: var(--black-sec);
    display:flex;
    justify-content:center;
    align-items:center;
    // margin-bottom: 15.9px;
    #content{
        display:flex;
        justify-content:center;
        align-items:center;
        flex-direction:column;
        p{
            color: var(--secondary-white);
            text-align:center;
            margin-top:30px;
            font-size:28px;
            width: 483px;
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            white-space: normal;
        }
    }
}
#not-available{
    margin-bottom: 15.9px;
}


.fs-program-not-avaialable {
    height: calc(100vh - 118px) !important;
}

#model-pay, #select-plan-pad{
    #plan-details{
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 5px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: #2F2F30;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #575757;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #454546;
        }
    }
}

.modal-open {
    padding-right: 0 !important;
}


.vjs-live-control{
    display: none !important;
}

.video-js .vjs-duration-display{
    margin-left: 0px !important;
}

#epg-loader {
    height: calc(var(--live-page-height) + 80px); //80px is the height of header
    width: 100%;
    position: fixed;
}

#epg-component .video-js .vjs-big-play-button {
    position:absolute;
    z-index:1;
    display:none!important;
}

@media screen and (min-width: 2300px) {
    .title-row .title, p#prgsynopsis {
        // width: 1400px!important;
    }
    .home-bg {
        background-color: var(--page-bg);
        a {
            color: var(--bg-font-color);
        }
    }
}

@media screen and (max-width: 1920px) {
    .prog-container .content-detail{
        width: 894px!important;
    }
    .title-row .title, p#prgsynopsis {
        // width: 1200px!important;
    }
}

@media screen and (max-width: 1810px) {
   .prog-container .content-detail {
        // width: 760px!important;
    }
}

@media screen and (max-width: 1680px) {
    .prog-container .content-detail {
        width: 650px!important;
    }
    .title-row .title, p#prgsynopsis {
        // width: 800px!important;
    }
    .home-bg {
        background-color: var(--page-bg);
        a {
            color: var(--bg-font-color);
        }
    }
}

@media screen and (max-width: 1600px) {
    .title-row .title, p#prgsynopsis {
        // width: 870px!important;
    }
    .prog-container .content-detail {
        width: 494px!important;
    }
}

@media screen and (max-width: 1520px) {
    .program-item{
        overflow-x: hidden;
    }
    .prog-container .content-detail {
        width: 482px!important;
    }
    .title-row .title, p#prgsynopsis {
        // width: 660px!important;
    }
}

@media screen and (max-width: 1440px) {
    .prog-container .content-detail {
        width: 470px!important;
    }
}

@media screen and (max-width: 1366px) {
    .prog-container .content-detail {
        width: 420px!important;
    }
    .title-row .title, p#prgsynopsis {
        // width: 680px!important;
    }
}

@media screen and (max-width: 1330px) {
    .title-row .title, p#prgsynopsis {
        // width: 494px!important;
    }
    .prog-container .content-detail {
        width: 350px!important;
    }
}
@media screen and (max-width: 1136px) {
    .title-row .title, p#prgsynopsis {
        // width: 390px!important;
    }
}

@media screen and (max-width: 1024px) {
    .title-row .title, p#prgsynopsis {
        width: 290px!important;
    }
    .prog-container .content-detail {
        width: 400px!important;
    }
    .title-row .title {
        font-size: 24px!important;
    }
    .program-item {
        padding-left: 71px!important;
        padding-right: 27.6px!important;
    }
    .prog-container .show-time {
        font-size: 28px!important;
        width: 129px!important;
    }
    .prog-container .show-logo {
        padding-left: 17px!important;
        padding-right: 42px!important;
        // width: 221.2px!important;
    }
}

@media screen and (max-width: 980px) {
    .prog-container .content-detail {
        width: 340px!important;
    }
}
.home-bg {
    background-color: var(--page-bg);
    a {
        color: var(--bg-font-color);
    }
}

// #video-player-control-bar-bg {
//     background-image: linear-gradient(to top, rgba(23, 23, 24, 0.7), rgba(46, 46, 46, 0) 80%);
//     height: 60px;/*176px;*/
//     bottom: 0;
//     width:928px;
//     position: absolute;
//     z-index:2;
// }

.fs-channelList-height {
    height: calc(100vh - 118px);
    overflow-y: scroll;
        &::-webkit-scrollbar {
        width: 3px;
        }
        &::-webkit-scrollbar-track {
        background:  #9c9c9c;
        }
        &::-webkit-scrollbar-thumb {
        background:var(--button-color);
        }
}
.fullscreenvideo {
    height: 100vh !important;
  }
  .hidescrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }
  .hidescrollbar::-webkit-scrollbar {
  display: none;
  }
#normal-screen-bottom-arrow {
    left: calc(50% - 12px);
    position: absolute;
    bottom: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
#normal-screen-top-arrow {
    width: 100%;
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 48px;
    cursor: pointer;
    display: none;
    gap: 12px;
    bottom: 70px;
    background-color: var(--bg-page);
    .back-to-player {
        left: 12px;
        font-size: 16px;
        line-height: 26px;
        color: var(--primary-white);
        font-family: var(--font-regular);
    }
}
.init-top {
    position:relative;
    width: 100%;
}
.footer-zindex {
    z-index: 1 !important;
}
.fullscreenvideo{
	.vjs-text-track-display {
    height: 100vh!important;
	}

}
html:has(#epg-component) {
    #header-section{
        background: var(--black-sec)!important; /* rgba(23,23,24,1)!important;*/
    }
}
//Ad Vast CSS
div.vast-skip-button {
    bottom: 110px;
}

.vast-blocker {
    pointer-events: none;
}

.vjs-vast-label{
    display: none !important;
}

// .vjs-quality-button{
//     display: none !important;
// }

.uk-notification{
    z-index:1050!important;
}
.uk-modal{
    z-index: 1045 !important;
}

.break-word{
    word-break: break-word;
}
.width-auto{
    width: auto;
}
.height-auto{
   height: auto !important;
}
.cursor-pointer{
    cursor: pointer !important;
}
.cursor-default{
    cursor: default !important;
}
.text-center{
    text-align: center;
}
.mt-0{
    margin-top:0px;
}
.mt-10{
    margin-top:10px;
}
.mt-20{
    margin-top:20px;
}
.mt-80{
    margin-top:80px;
}
.mb-0{
    margin-bottom:0px;
}
.mb-10{
    margin-bottom:10px;
}
.mb-20{
    margin-bottom:20px;
}
.mb-80{
    margin-bottom:80px;
}
.text-nowrap {
    white-space: nowrap;
}
.display-none {
    display: none;
}
.display-block {
   display: block;
}
.text-nowrap {
    white-space: nowrap;
}
.uk-offcanvas-bar{
    .nav-link{ 
        display:block;
    }
}
.smart-logo{
    top: 120px !important;
    position: fixed;
    width: 928px;
    margin: 0 auto;
    text-align: right;
    left: calc((100% - 928px)/2);
    padding-right: 70px;
    z-index:1;
    img{
        max-height:45px!important;
    }
}
.fullscreenvideo {
    .smart-logo{
        top: 70px !important;
        padding-right: 70px;
        width: 100%;
        left: unset;
        img{
            max-height:65px!important;
        }
    }
}
.adsmart-logo{
    top: 70px !important;
    padding-right: 70px;
    width: 100%;
    left: unset;
    img{
        max-height:65px!important;
    }
}
@media screen and (max-width: 470px) {
    #signup-card-pad, .signin-card {
        // padding: 0px 36px!important;
    }
    #model-pay #plan-details, #select-plan-pad #plan-details {
        overflow-x: hidden;
    }
}
#signup-card-pad {
    #signup-step {
        img {
            left: 0px!important;
        }
    }
}
.mobile-password-desc-text{
    color: var(--primary-white);
    padding: 0px 20px;
}
.live-video-player-woepg{
    .video-js .vjs-control-bar .vjs-progress-control,
    .vjs-time-control {
        display: none !important;
    };
}
.slick-slider .slick-track, .slick-slider .slick-list{
    z-index: 1;
}
.banner:has(.slick-list) {
    z-index:0!important;
   .slick-dots {
        z-index: 1;
        margin-left: 122px;
        // margin-bottom: 37px;
        left: 0px!important;
        text-align: left;
    }
}


@media screen and (max-width: 767px) {
    .banner .slick-dots {
        margin-left: 20px!important;
        left: 0px!important;
        text-align: left;
    }
    .banner-info{
        left:20px!important;
        width: 60%!important;
    }
    .banner .slick-dots-port .slick-dots {
        text-align: center !important;
        position: relative !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
	.banner .slick-dots{
		margin-left: 50px!important;
        left: 0px!important;
        text-align: left;
	}
    .banner-info{
        left:50px!important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .banner .slick-dots {
        margin-left: 82px!important;
        left: 0px!important;
        text-align: left;
    }
    .banner-info{
        left:82px!important;
    }
}

@media (min-width: 1200px) and (min-width: 1370px) {
    .banner .slick-dots {
        margin-left:122px !important;
        left: 0px!important;
        text-align: left;
    }
    .banner-info{
        left:130px!important;
    }
}

.device-name .device-name-container .device-edit button{
    display: unset !important;
    justify-content: unset !important;
    padding: 1px 6px !important;
    align-items: unset !important;
    margin: unset !important;
}
@media only screen and (min-width:471px){
    .device-name .device-name-container .device-edit button{
        width: unset !important;
    }
}
.place .dt-btn button{
    padding: unset !important;
}
.device-signout #signOut-device-hide{
    justify-content: flex-end !important;
}
#all-signout #signOut-device-hide{
    justify-content: flex-end !important;
}
#signin-pad #change-password #change-password-hide{
    justify-content: flex-end !important;
}
#plan-bg:has(.multi-plan:not(:only-child)){
    #plan-card{
        width:928px!important;
        max-width: unset;
    }
    .form-card {
        max-width: unset;
    }
}

#voucher-bg:has(.multi-plan:not(:only-child)){
    #voucher-card-bg{
        width:928px!important;
        max-width:unset;
    }
    .form-card {
        max-width: unset;
    }
}
#redeem-voucher-screen:has(.multi-plan:not(:only-child)){
    .uk-modal-dialog{
        width:928px!important;
    }
}
#open-pay-dialog:has(.multi-plan:not(:only-child)){
    .uk-modal-dialog{
        width:928px!important;
    }
}
#open-pay-dialog:has(.avodsvod-details:not(:only-child)){
    .uk-modal-dialog{
        width:928px!important;
    }
}
#open-pay-dialog, #open-upgrade-dialog{
    z-index: 1046;
    .uk-modal-dialog {
        text-align: center;
        background-color: var(--bg-page);
        background-repeat: no-repeat;
        border: 1px solid var(--card-border);
        border-radius: 0px !important;
        padding:32px!important;
        color: var(--mm-font-color);
    }
}
#open-upgrade-dialog:has(.multi-plan:not(:only-child)){
    .uk-modal-dialog{
        width:928px!important;
    }
}

@media only screen and (max-width:470px) {
    #voucher-bg:has(.multi-plan:not(:only-child)){
        #voucher-card-bg{
            width: unset!important;
            max-width:unset;
        }
        .form-card {
            max-width: unset;
        }
    }
    #voucher-card{
        width: var(--auth-card-width);
    }
    #plan-bg:has(.multi-plan:not(:only-child)) {
        #plan-card {
        width: unset!important;
        max-width: unset;
        }
    }
}

.ima-seek-bar-div{
    width: 98% !important;
    margin-left: 1% !important;
    bottom: unset !important;
}
.ima-progress-div{
    background-color: var(--border-color) !important;
}
.ima-slider-level-div{
    background-color: var(--border-color) !important;
}

@media (min-width: 768px){
    .navbar-right {
        margin-right: 0px!important;
    }
}

/************* new responsive styles start *****/
@media (min-width: 320px) and (max-width: 1024px) and (orientation:landscape){
    .slick-next {
        right: 0px!important;
    }
    .slick-prev {
        left: 0px!important;
    }
    .vjs-info{
        left:0px!important;
        .vjs-ttl{
            padding-left:30px;
        }
    }
    .pin-control-container{
        overflow-y:scroll;
    }
    #live-video-player .video-js .vjs-tech {
        // height: 100vh;
        position:fixed;
        width:100vw;
    }
}

// @media (min-width: 320px) and (max-width: 1024px) and (orientation:portrait) {
//     .slick-next {
//         right: 0px!important;
//     }
//     .slick-prev {
//         left: 0px!important;
//     }
//         #video-player{
//             .video-js .vjs-tech{
//                 width:100vw!important;
//                 position:absolute!important;
//             }
//             .vjs-subs-caps-button, .vjs-quality-button.vjs-menu-button.vjs-control.vjs-button:before{
//                 display:none!important;
//             }
//             .vjs-info{
//                 // left:30px!important;
//             }
//         }
//         #trailer-player{
//             .vjs-forward-control, button.vjs-rewind-control, .start-over, .all-episodes, .vjs-quality-button.vjs-menu-button.vjs-control.vjs-button:before, .vjs-current-time, .vjs-duration{
//                 // display:none!important;
//             }
//         }
//         #live-video-player{
//             .video-js .vjs-tech{
//                 width:100vw!important;
//                 position:absolute!important;
//             }
//             .vjs-forward-control, button.vjs-rewind-control, .vjs-subs-caps-button, .vjs-quality-button.vjs-menu-button.vjs-control.vjs-button:before, .vjs-current-time, .vjs-duration{
//                 // display:none!important;
//             }
//         }
//         .next-episode-panel{
//             width:90%!important;
//             margin-left:30px!important;
//         }
// }


@media (min-width: 481px) and (max-width: 1024px) and (orientation: landscape) {
	.vjs-control-bar > .vjs-control {
		// margin-left: 10px !important;
	}
    .player-page .button-back {
        left: 3%!important;
        top:33px!important;
    }
    button.vjs-forward-control.vjs-control.vjs-button {
        // right: 36% !important;
        right: calc(50% - 106px) !important;
    }
    button.vjs-rewind-control.vjs-rewind-first.vjs-control.vjs-button {
        // left: 34% !important;
    }
    .vjs-info{
        font-size: 24px!important;
        top: 15px !important;
        left: 4%!important;
    }

}

@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
	.vjs-control-bar > .vjs-control {
		margin-left: 10px !important;
	}
    .player-page .button-back {
        left: 3%!important;
        top:33px!important;
    }
    button.vjs-forward-control.vjs-control.vjs-button {
        // right: 22% !important;
        right: calc(50% - 106px) !important;
    }
    button.vjs-rewind-control.vjs-rewind-first.vjs-control.vjs-button {
        // left: 20% !important;
        left: calc(50% - 106px) !important;
    }
    .vjs-info{
        font-size: 20px!important;
        top: 18px !important;
        left: 8%!important;
    }
    .vjs-480.vjs-has-started .vjs-big-play-button {
        // margin: -44px 0 0 -33px!important;
        margin:0px!important;
    }
    .vjs-duration.vjs-time-control.vjs-control {
        bottom:70px;
    }
    .vjs-current-time.vjs-time-control.vjs-control {
        bottom: 70px;
    }

}
/************* new responsive styles end ******/


/*** menu styles start ***/
@media (min-width: 1025px){
    a.category {
        .caret{
            display:none!important;
        }
    }
}

@media (min-width: 320px) and (max-width: 1281px) {
    #listCategory {
        width: 100%;
        .dropdown-menu{
            top:0px!important;
        }
    }
    .subcategory-list {
        position: relative;
        top: 0px;
    }
    .home-bg {
        background-color: var(--page-bg) !important;
    }

    .navbar-fixed-bottom .navbar-collapse.in, .navbar-fixed-top .navbar-collapse.in {
        max-height: 100vh!important;
        width: 400px;
        float: right;
        background: var(--bg-page);
        height: calc(100vh - 80px)!important;
        top: 0px;
        right: 0px;
        position: absolute;
        padding:20px;
    }
    .all-items {
        flex-direction: column;
    }
    .navbar-toggle {
        float: left!important;
        border: none!important;
        background-color:transparent!important;
    }
    .navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
        display:none;
    }
    .multiple-items .slick-next, .multiple-items .slick-prev {
        display:none!important;
    }
    .vjs-menu.vjs-lock-showing {
        width: 300px!important;
    }
    .vjs-subs-caps-button > .vjs-menu > .vjs-menu-content, .vjs-audio-button > .vjs-menu > .vjs-menu-content, .vjs-quality-button > .vjs-menu > .vjs-menu-content {
        padding: 2rem 2rem 0px 0rem;
    }
    .subtitle {
        font-size: 26px!important;
    }
    .vjs-quality-button > .vjs-menu > .vjs-menu-content > .vjs-menu-item {
    font-size: 1.2rem;
    padding:8px!important;
    }
}

// @media (min-width: 300px) and (max-width: 600px) and (orientation: portrait) {
//     .vjs-play-control{
//         display: none !important;
//     }
//     .vjs-volume-panel{
//         display: none !important;
//     }
//     .vjs-fullscreen-control{
//         position: fixed !important;
//         bottom: 90vh;
//         top: unset;
//     }
//     .vjs-info .vjs-ttl{
//         width: 74%;
//     }
//     .video-js .vjs-current-time{
//         position: fixed !important;
//         left: 62% !important;
//     }
//     .vjs-duration{
//         position: fixed !important;
//         left: 82% !important;
//     }
//     .vjs-current-time.vjs-time-control.vjs-control{
//         width: 71px !important;
//     }
//     .vjs-480.vjs-has-started .vjs-loading-spinner{
//         margin: -36px 0 0 -25px!important;
//     }
// }

#verify-email{
    .otp-input{
        color: #000;
    }
    .otp-input:focus{
        outline: 2px solid var(--secondary-red) !important;
    }
}

.banner .slick-dots {
    z-index: 1;
}
.vjs-480 .vjs-duration{
    display: block !important;
}
.vjs-480 .vjs-spacer{
    display: none !important;
}
.vjs-480 .vjs-progress-control .vjs-slider:before{
    position: absolute;
}

#footer-description {
    height: 700px;
    overflow-y: auto;
    font-family: var(--font-regular);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--card-font-color);
    overflow-x: auto;
    margin-bottom:100px;
    padding-right: 9px;
    p, p span, em, h1 span, h1 span span, h3 span,  h3 span span{
        color: var(--card-font-color)!important;
    }
    a{
        color:var(--button-color);
    }
}
/**** menu styles end ****/

// mat progress bar styles start

.mdc-linear-progress__bar-inner{
    border-color: var(--button-color)!important;
}

.mdc-linear-progress__buffer {
    background-color: var(--bg-font-color)!important;
}

// mat progress bar styles end

.mat-mdc-dialog-container .mdc-dialog__surface{
    background-color: transparent !important;
}

@media (min-width: 320px) and (max-width: 767px) {
    .warning-strip {
        top:0px;
    }

    .highlight{
        margin-top:80px!important;
    }
	.content:has(.join-now-block){
		.highlight{
            margin-top:0px!important;
        }
	}
}
// To prevent image dragging - issue id: TV2ZP-I1395
img {
    user-drag: none; /* Standard syntax */
    -webkit-user-drag: none; /* WebKit/Blink browsers */
    -moz-user-drag: none; /* Gecko browsers */
}

.mat-mdc-radio-button.mat-accent{
    --mat-radio-checked-ripple-color: transparent !important
}



/*** Tablets ipads specific styles start ***/

@media (min-width: 768px) and (max-width: 1024px) {


}


@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

}

@media (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
    .my-full-screen-season .mat-mdc-dialog-container {
        min-width: 100vw;
        min-height: 400px;
        height: 450px;
        bottom: 0px;
        top: unset;
    }
    .seasons-count-sec{
        // gap:10px;
    }
    .season-buttons, .episodes-contents {
        margin-left: 20px !important;
    }
    .seasons-box {
        display: none!important;
    }
    .titleName {
        display: none;
    }
    .seasons-bg-img{
        height: 450px !important;
        min-height: 350px !important;
    }
    .episodes-data{
        height: 450px!important;
        row-gap: 20px;
        padding-top: 20px;
    }
    .episodes-contents{
        margin-top:10px;
    }
    .seasons-count, #scrollableEpisodesDiv {
        overflow-y: hidden!important;
        &::-webkit-scrollbar {
                height: 5px;
            }
            /* Track */
            &::-webkit-scrollbar-track {
                background: #2F2F30;
            }
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #575757;
            }
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #454546;
            }
        }
}


@media (min-width: 320px) and (max-width: 1024px) and (orientation: portrait) {
    .my-full-screen-season .mat-mdc-dialog-container {
        min-width: 100vw;
        min-height: 400px;
        height: 450px;
        bottom: 0px;
        top: unset;
    }
    #scrollableEpisodesDiv {
        display: flex!important;
        overflow-y: hidden !important;
    }
    .season-card{
        display: block!important;
        margin-top: 10px !important;
    }
    .seasons-count{
        display: flex!important;
        height: auto!important;
        overflow-y: hidden;
    }
    .seasons-count-sec{
        display:flex;
        flex-direction: row!important;
    }
    .season-buttons{
        display: flex!important;
        white-space: nowrap;
        margin-left: 20px;
        margin-right: 0px !important;
    }
    .seasons-box {
        display: none!important;
    }
    .titleName {
        display: none;
    }
    .seasons-bg-img{
        height: 450px !important;
        min-height: 350px !important;
        width: 100vw !important;
    }
    .episodes-data{
        height: 450px!important;
        flex-direction: column;
        row-gap: 20px;
        padding-top: 20px;
    }
    .episodes-box {
        width: 100% !important;
        // overflow-x: scroll;
        // overflow-y: hidden !important;
    }

    .seasons-count, #scrollableEpisodesDiv {
        overflow-y: hidden!important;
    &::-webkit-scrollbar {
            height: 5px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: #2F2F30;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #575757;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #454546;
        }
    }
}

/*** Tablets ipads specific styles end  ***/