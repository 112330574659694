/*extra small devices*/

@media screen and (max-width: 479px) {
    .signin a {
        padding: 8px 18px;
        font-size: 14px;
    }
    .text-wrap {
        margin-top: 40%
    }
    .basic-title {
        color: var(--card-font-color);
        font-size: 1.5em;
        margin: 0 0 .2em;
        line-height: 1em;
        font-weight: 700;
    }
    .basic-para {
        letter-spacing: .2px;
        padding: 0 10px;
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 20px;
        margin-top: 20px;
        color: var(--card-font-color);
    }
    .form-signup-facebook .btn {
        background-color: #3b5998;
        font-size: 12px;
        text-transform: inherit;
        width: 100%;
        border: medium none;
        border-radius: 4px;
        color: #fff;
        display: inline-block;
        font-weight: normal;
        line-height: 40px;
        padding: 0 20px;
        position: relative;
        z-index: 1;
        margin-top: 15px;
    }
    .form-signup-google .btn {
        background-color: #dc4e41;
        font-size: 12px;
        text-transform: inherit;
        width: 100%;
        border: medium none;
        border-radius: 4px;
        color: #fff;
        display: inline-block;
        font-weight: normal;
        line-height: 40px;
        padding: 0 20px;
        position: relative;
        z-index: 1;
        margin-top: 15px;
    }
    .social-login {
        text-align: center;
    }
    .pull-left1 {
        text-align: center;
    }
    .pull-right1 {
        text-align: center;
    }
    .highlight-img {
        height: 100%;
        background-position: center 10%;
        width: 100%;
    }
    .highlight {
        position: relative;
        height: 250px;
        margin: 0px 0px 80px;
    }
    .header_section {
        padding: 0 20px;
        width: 100%;
    }
    .navbar-default {
        border-radius: 0;
        border: none;
        margin-bottom: 0;
        box-shadow: 0px 2px 4px #212121;
    }
    .nav>li {
        display: inline-block !important;
    }
    .left-align {
        float: left;
    }
    .right-align {
        float: right;
    }
    /* .open .dropdown-menu {
        float: none;
        width: auto;
        margin-top: 0;
        background-color: var(--mm-color) !important;
        border: 1px solid var(--bg-color) !important;
        -webkit-box-shadow: none;
        box-shadow: none;
        position: absolute !important;
        margin-top: -55px;
        margin-left: 5%;
    } */
    .border-right {
        border-right: 0 !important;
    }
    .navbar-right .dropdown-menu {
        right: 15px;
        left: auto;
    }
    .centered-div {
        transform: scale(1);
        opacity: 1;
        transition-duration: 450ms;
        transition-delay: 200ms;
        width: 100%;
        padding: 20px 10px;
        margin: auto;
    }
    .profile-entry input[type="text"],
    .profile-entry input[type="email"] {
        width: 15em !important;
    }
    .navbar-nav {
        margin: 3.5px 0px
    }
    .navbar-nav .open .dropdown-menu {
        width: 160px;
    }
    .height {
        height: 290px;
    }
    .start-btn {
        /* width: 160px; */
        margin-top: 3%;
        text-align: center !important;
        background-color: var(--button-color);
        border-radius: 50px;
        padding: 10px 20px;
        font-size: 18px;
        color: var(--button-font-color) !important;
        font-weight: 700;
    }
    .limit {
        max-width: 64%;
        height: 20px;
        color: var(--mm-font-color);
        background-color: transparent;
        overflow-x: auto;
    }
    /* ::-webkit-scrollbar {
        display: none;
    } */
    .leftArrow {
        margin-top: -6px;
        margin-right: 2%;
        margin-left: 3%;
        color: var(--button-color) !important;
        font-size: 24px;
    }
    .rightArrow {
        margin-top: -6px;
        margin-right: 2%;
        color: var(--button-color) !important;
        font-size: 24px;
    }
    .sliderSection {
        display: inline-flex;
        width: 75%;
    }
    .slick-prev {
        left: 0;
        display: none !important;
    }
    .slick-next {
        right: 0;
        display: none !important;
    }
    .subscribe {
        margin-top: -2%;
        margin-left: -55px;
        color: var(--button-font-color);
        text-align: right;
        font-weight: 700;
        position: fixed;
        background-color: var(--button-color);
        border: 1px solid var(--button-color);
        border-radius: 50px;
        font-size: 14px;
    }
    .logo img {
        width: 120px;
        height: 40px;
    }
    .details-page {
        margin-top: 110px !important;
        margin: 0% 5%;
        padding: 10px;
    }
    /* changing mobile view to slider in home and category page */
    .home-box-title {
        color: var(--bg-font-color);
        padding: 10px;
        margin: 15px 0 0px 0;
    }
    .video-box-row {
        overflow-x: scroll;
        white-space: nowrap !important;
        overflow-y: hidden;
    }
    .details-video-box-outer {
        margin-right: 5%;
        margin-left: 5%;
    }
    .single-tab-content {
        margin-top: 65px;
    }
    .single-tab-content1 {
        margin-top: 135px;
    }
    .playlist-title {
        font-size: 14px !important;
        color: var(--bg-font-color);
        font-weight: 700;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 2%;
        width: 95% !important;
    }
    .morelike-box-height {
        height: 100px;
    }
    .video-img {
        height: 100px;
        width: 90%;
    }
    .single-subcategory {
        color: var(--bg-font-color);
        font-weight: 700;
        margin-top: 6%;
        margin-left: 4%
    }
    .home_top_class {
        margin: 2% 3% 0% 3%;
    }
    .category-page {
        margin-top: 110px !important;
        margin: 2% 3% 30% 3%;
    }
    #page_preloader>img {
        display: block;
        position: absolute;
        left: 48%;
        top: 50%;
        margin: -24px 0 0 -24px;
        width: 15% !important;
        height: auto
    }
    #before_loader>img {
        display: block;
        position: absolute;
        left: 48%;
        top: 50%;
        margin: -24px 0 0 -24px;
        width: 15%;
        height: auto
    }
    .more-profile>li>a {
        margin-bottom: 15px;
        display: inline-block;
    }
    .dropdown-menu li>a {
        color: var(--mm-font-color);
        margin-bottom: 15px;
        display: block;
        font-size: 15px;
        font-weight: 550;
    }
    .account-section-link {
        color: #0080FF;
        font-size: 16px;
        display: inline-block;
        margin-bottom: 3%
    }
    .form-content {
        min-height: 980px;
    }
    .login-form-inner {
        max-width: 500px;
        margin: 0 auto;
        padding: 40px;
        margin-top: 9%;
    }
    .spark-style {
        margin-left: 2%;
    }
    .video-views {
        font-size: 13px !important;
    }
    .social-btn-like {
        padding: 8px 10px !important;
    }
    .social-share {
        top: 35px !important;
        right: 120px !important;
        z-index: 10;
    }
    .add-to-wl>img {
        width: 21px !important;
    }
    .image-center {
        height: 300px;
        line-height: 300px;
    }
    .device-join-txt {
        font-size: 16px;
    }
    .vistv-content>.about-vistv-title {
        display: block;
    }
    .footer-logo {
        margin-top: -15px;
        width: 120px;
    }
    .table-borde-black {
        width: 92%;
    }
    .tile-details-left-space {
        left: 5px;
        width: 97%;
        bottom: -1px;
    }
    .right-side-actions {
        margin: 10px 1px !important;
    }
    .user-comment-section {
        padding-right: 0px;
        padding-left: 0px;
    }
    .comment-profile-pic {
        width: 46px;
        height: 40px;
    }
    .profile-button a,
    .profile-button span {
        font-size: 12px !important;
    }
    .profiles-gate-container .profile-name {
        font-size: 18px !important;
    }
    .profile-button a,
    .profile-button span {
        font-size: 12px;
        padding: 12px;
    }
    .no-pad {
        padding: 0 0 10px 0px;
    }
    .my-account-cards {
        height: 260px;
    }
    .no-margin {
        margin: 10px 0 !important;
    }
    .see-all {
        font-size: 13px;
    }
    .overview-details-hide-xs {
        display: none !important;
    }
    .play-icon {
        font-size: 22px;
        padding: 8px 15px;
        height: 50px;
        width: 50px;
    }
    .linear-gradient {
        display: none;
    }
    .fa-chevron-circle-left {
        display: none;
    }
    .fa-chevron-circle-right {
        display: none;
    }
    .account-layout-center {
        width: auto;
        margin: 0 10px;
    }
    .tab {
        width: 30% !important;
    }
    .tabcontent {
        width: 70% !important;
        padding: 0px !important;
    }
    .series-single-episode-desc {
        padding: 0px !important;
    }
    .series-video-sm-no-margin {
        margin-left: 0px !important;
    }
    .tabcontent>div {
        padding: 0px;
    }
    .ads {
        margin-top: 7%;
        overflow: scroll;
    }
    .details-video-details {
        margin-bottom: 0%;
        margin-left: 5.5%;
        width: 89%;
        height: auto;
    }
    .content-wrapper {
        margin: 100px 0 40px;
    }
    .profile-metadata img {
        height: 8vw;
        max-height: 180px;
        max-width: 180px;
        min-height: 75px !important;
        min-width: 75px !important;
        width: 8vw;
        border-radius: 100px;
        margin-left: 29px;
    }
    .single-video-right-actions1 {
        display: inherit!important;
        margin: 15px 0% 0 10%;
        font-size: 14px;
        padding: 0px !important;
    }
    .subscription-tag {
        min-height: 50px;
        background: linear-gradient(to right, #b82020, #3356ff);
        text-align: center;
        margin: 10px;
        padding: 10px;
        font-size: 20px;
        margin: 30px 1% 0;
    }
    .single-video-right-actions {
        display: inherit!important;
        margin: 20px 0% 20px 10%;
        font-size: 14px;
        padding: 0px !important;
    }
    .spacing1 {
        padding: 20px 5px;
        color: var(--card-font-color);
    }
    .condition-checkbox {
        margin-right: 15px;
        min-width: 20px;
        height: 20px;
    }
    .chat {
        height: auto !important;
    }
    .live-transition-class {
        display: none;
    }
    .tab {
        width: 100% !important;
        height: unset !important;
        margin: 30px auto;
    }
    .tabcontent {
        width: 100% !important;
    }
    .series-drop-tab {
        padding-top: 20px;
    }
    .episode-image {
        width: 100%;
        height: unset !important;
        border-radius: 8px;
        margin-bottom: 0px;
    }
    .series-video-page-grid {
        overflow: hidden;
        padding-top: 25px !important;
        z-index: 10;
        position: relative;
        display: inline !important;
    }
    .ppv_background_image {
        max-width: 100% !important;
    }
    .ppv-video-content {
        width: 88.9% !important;
    }
    .ppv-choose-user-type {
        padding: 0 0px;
    }
    .ppv-bottom-margin {
        margin-bottom: 700px;
    }
    .ppv-page-title {
        padding: 0 10px;
    }
    .ppv-purchase-your-way {
        padding: 0 10px;
    }
    .no-bg-yellow-right {
        float: right;
    }
    .payment-option-boxes {
        margin-bottom: 15px;
    }
    .detail-bg--clear {
        z-index: -1;
        height: 100vh;
        max-height: 50vh !important;
        background-color: #333;
        background-size: cover;
        -webkit-mask-position: 0 0;
        -webkit-mask-size: 100% 100%;
        -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0, 0, 0, 1)), color-stop(.1, rgba(0, 0, 0, 1)), color-stop(0.3, rgba(0, 0, 0, 0.1)), color-stop(1, transparent));
    }
    ul.tabs li.current {
        font-size: 18px !important;
        margin-bottom: 0px !important;
    }
    ul.tabs li {
        font-size: 18px !important;
        margin-bottom: 0px !important;
    }
    .btn-payment-subn-type {
        margin-top: 15px !important;
    }
    .payment-container {
        margin-top: 50px;
    }
    .search-logo {
        top: 5px;
        font-size: 25px;
    }
    .details-tile-content {
        margin-bottom: 0;
    }
    .details-video-details {
        max-width: 300px;
        margin: auto;
    }
    .series-actions {
        display: inline-block !important;
    }
    .series-play-button {
        padding: 8px 15px;
        font-size: 14px;
        border-radius: 50px;
        text-transform: none;
        background-color: var(--button-color);
        border: 2px solid var(--button-color);
        margin: 15px 7px;
        outline: 0px !important;
        width: max-content;
        color: var(--button-font-color);
        font-weight: 600;
    }
    .episode.title {
        margin-top: 0px;
        font-size: 14px;
        font-weight: 600;
    }
    .series-info {
        width: 250px;
        margin: auto;
    }
    .tile-content {
        position: absolute;
        padding: 0 3%;
        left: 0;
        right: 0;
        bottom: 0;
        margin-bottom: 0%;
        color: var(--primary-white);
    }
    .tile-tit {
        margin-bottom: -6px;
    }
    .series-tab-content {
        padding: 0px 10px !important;
    }
    .tab-wrap {
        font-size: 1.8em;
    }
    .paid-tag {
        right: 3px;
    }
    .question-section {
        padding: 20px;
        color: var(--font-color);
    }
    .polling-bg-card {
        margin-bottom: 20px;
        height: 500px !important;
        width: 100% !important;
        margin: auto;
    }
    .avatar-image {
        width: 100px;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        background-size: cover;
        border-radius: 100%;
        cursor: pointer;
    }
    .avatar-image:hover {
        width: 90px;
        height: 90px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        background-size: cover;
        border-radius: 100%;
        cursor: pointer;
    }
    .subscription-card {
        width: 320px;
        padding: 20px 0px;
        margin: 0px auto;
    }
    /* .cancel-details {
        width: auto;
        padding: 31px 10px 24px;
    } */
}


/*small devices*/

@media (min-width: 480px) and (max-width: 768px) {
    .basic-title {
        color: var(--card-font-color);
        font-size: 2.0em;
        margin: 0 0 .5em;
        line-height: 1em;
        font-weight: 600;
    }
    .basic-para {
        letter-spacing: .2px;
        padding: 0 10px;
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 20px;
        margin-left: -1%;
        color: var(--card-font-color);
    }
    .form-signup-google .btn {
        background-color: #dc4e41;
        float: left;
        font-size: 12px;
        text-transform: inherit;
        width: auto;
        border: medium none;
        border-radius: 4px;
        color: #fff;
        display: inline-block;
        font-weight: normal;
        line-height: 40px;
        padding: 0 20px;
        position: relative;
        z-index: 1;
        margin-top: 15px;
    }
    .form-signup-facebook .btn {
        background-color: #3b5998;
        float: right;
        font-size: 12px;
        text-transform: inherit;
        width: auto;
        border: medium none;
        border-radius: 4px;
        color: #fff;
        display: inline-block;
        font-weight: normal;
        line-height: 40px;
        padding: 0 20px;
        position: relative;
        z-index: 1;
        margin-top: 15px;
    }
    .pull-left1 {
        float: left;
    }
    .pull-right1 {
        float: right;
    }
    .highlight-img {
        margin-left: 0%;
        width: 100% !important;
        background-position: center 10%;
        width: 100%;
    }
    .highlight {
        position: relative;
        margin: 0px 0px 0px;
    }
    .header_section {
        padding: 0px;
        width: 100%;
        padding: 0 20px !important;
    }
    .navbar-default {
        border-radius: 0;
        border: none;
        margin-bottom: 0;
    }
    .nav>li {
        display: inline-block !important;
    }
    .left-align {
        float: left;
    }
    .right-align {
        float: right;
    }
    /* .open .dropdown-menu {
        float: none;
        width: auto;
        margin-top: 0;
        background-color: var(--mm-color) !important;
        border-right: 0 !important;
        -webkit-box-shadow: none;
        box-shadow: none;
        position: absolute !important;
        border: 1px solid var(--bg-color);
        margin-top: -55px;
        margin-left: 5%;
    } */
    .border-right {
        border-right: 0 !important;
    }
    .navbar-right .dropdown-menu {
        right: 15px;
        left: auto;
    }
    .centered-div {
        transform: scale(1);
        opacity: 1;
        transition-duration: 450ms;
        transition-delay: 200ms;
        width: 100%;
        padding: 15px;
        margin: auto;
        padding: 60px 30px;
    }
    .navbar-nav {
        margin: 3.5px 0px;
    }
    .height {
        height: 275px;
    }
    .start-btn {
        background-color: var(--button-color);
        border-radius: 50px;
        padding: 10px 20px;
        font-size: 18px;
        color: var(--button-font-color) !important;
        font-weight: 700;
    }
    .limit {
        max-width: 68%;
        height: 20px;
        color: var(--mm-font-color);
        background-color: transparent;
        overflow-x: auto;
        margin-left: -3%;
    }
    /* ::-webkit-scrollbar {
        display: none;
    } */
    .leftArrow {
        margin-top: -7px;
        margin-right: 2%;
        margin-left: 2%;
        color: var(--button-color) !important;
        font-size: 26px;
    }
    .rightArrow {
        margin-top: -7px;
        margin-right: 2%;
        color: var(--button-color) !important;
        font-size: 26px;
    }
    .sliderSection {
        display: inline-flex;
        width: 80%;
        margin-bottom: 5px;
    }
    .subscribe {
        margin-top: -10px;
        margin-left: -30px;
        color: var(--button-font-color);
        text-align: center;
        font-weight: 700;
        background-color: var(--button-color);
        border: 1px solid var(--button-color);
        border-radius: 50px;
        font-size: 14px;
    }
    .logo img {
        width: 120px;
        height: 40px;
    }
    .slick-prev {
        left: 0;
        display: none !important;
    }
    .slick-next {
        right: 0;
        display: none !important;
    }
    .details-page {
        margin: 0% 60px;
        margin-top: 130px !important;
        padding: 10px;
    }
    .home-box-title {
        color: var(--bg-font-color);
        padding: 10px;
        margin: 10px 0 1%;
    }
    .video-box-row {
        overflow-x: hidden;
        white-space: nowrap !important;
        padding-bottom: 5px;
        overflow-y: hidden;
        margin-bottom: 10px;
    }
    .details-video-box-outer {
        margin-right: 5%;
        margin-left: 5%;
    }
    .playlist-title {
        font-size: 14px !important;
        color: var(--bg-font-color);
        font-weight: 700;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-top: 10px;
        margin-bottom: 2%;
        width: 95% !important;
    }
    .single-tab-content,
    .single-tab-content {
        margin-top: 60px;
    }
    .video-img {
        width: 80%;
        height: 100%;
        cursor: pointer;
    }
    .single-subcategory {
        color: var(--bg-font-color);
        font-weight: 700;
        margin-top: 4.5%;
        margin-left: 3%
    }
    .home_top_class {
        margin: 2% 3% 0% 3%;
    }
    .category-page {
        margin-top: 110px !important;
        margin: 2% 3% 30% 3%
    }
    .account-layout {
        margin-top: 10%;
    }
    #page_preloader>img {
        display: block;
        position: absolute;
        left: 48%;
        top: 50%;
        margin: -24px 0 0 -24px;
        width: 10% !important;
        height: auto
    }
    #before_loader>img {
        display: block;
        position: absolute;
        left: 48%;
        top: 50%;
        margin: -24px 0 0 -24px;
        width: 10%;
        height: auto
    }
    .more-profile>li>a {
        margin-bottom: 15px;
        display: inline-block;
    }
    .dropdown-menu li>a {
        color: var(--mm-font-color);
        margin-bottom: 15px;
        display: block;
        font-size: 15px;
        font-weight: 550;
    }
    .account-section-link {
        color: #0080FF;
        font-size: 16px;
        display: inline-block;
        margin-bottom: 3%
    }
    .login-form-inner {
        max-width: 500px;
        margin: 0 auto;
        padding: 40px;
        margin-top: 9%;
    }
    .spark-style {
        margin-left: 2%;
    }
    .social-share {
        top: 40px !important;
        right: 120px !important;
    }
    .image-center {
        height: 350px;
        line-height: 350px;
    }
    .vistv-content>.about-vistv-title {
        display: block;
    }
    .footer-logo {
        margin-top: -5px;
        width: 120px;
    }
    .table-borde-black {
        width: 94.5%;
    }
    .profile-button a,
    .profile-button span {
        font-size: 12px !important;
        padding: 16px 14px !important;
    }
    .profiles-gate-container .profile-name {
        font-size: 18px !important;
    }
    .overview-details {
        display: none;
    }
    .linear-gradient {
        display: none;
    }
    .content-tit {
        line-height: 26px;
        height: 26px;
    }
    .content-subcat {
        font-size: 18px;
    }
    .show-content-category-left {
        margin-left: 30px !important;
        padding-left: 0px !important;
    }
    .fa-chevron-circle-left {
        display: none;
    }
    .fa-chevron-circle-right {
        display: none;
    }
    .tile-details-left-space {
        left: 5px;
        width: 97%;
        bottom: -1px;
    }
    .account-layout-center {
        width: auto;
        margin: 0 auto;
    }
    .ads {
        margin-top: 7%;
        overflow: scroll;
    }
    .tab {
        width: 30% !important;
    }
    .tabcontent {
        width: 70% !important;
        padding-left: 0px !important;
    }
    .series-single-episode-desc {
        padding: 0px;
    }
    .series-video-description {
        margin-left: 0px !important;
    }
    .details-video-details {
        margin-bottom: 0%;
        margin-left: 5.5%;
        width: 88%;
        height: auto;
        max-width: 300px;
        margin: auto;
    }
    .morelike-box-height {
        height: 150px;
    }
    .video-img {
        height: 150px;
        width: 90%;
    }
    .card-box {
        max-height: 480px !important;
    }
    .chat {
        height: auto!important;
    }
    .live-transition-class {
        display: none;
    }
    .card-list {
        padding: 0px 40px;
        margin-top: 50px;
    }
    .content-wrapper {
        margin: 100px 0 40px;
    }
    .single-video-right-actions1 {
        display: inherit!important;
        margin: 15px 0% 0 14% !important;
    }
    .subscription-tag {
        min-height: 50px;
        background: linear-gradient(to right, #b82020, #3356ff);
        text-align: center;
        margin: 10px;
        padding: 10px;
        font-size: 20px;
        margin: 30px 0px 0px;
    }
    .single-video-right-actions {
        display: inherit!important;
        margin: 120px 0% 0 14% !important;
    }
    .tab {
        width: 100% !important;
        height: unset !important;
        margin: 30px auto;
    }
    .tabcontent {
        width: 100% !important;
    }
    .series-drop-tab {
        padding-top: 20px;
    }
    .episode-image {
        width: 100%;
        height: unset !important;
        border-radius: 8px;
        margin-bottom: 0px;
    }
    .series-video-page-grid {
        overflow: hidden;
        padding-top: 25px !important;
        z-index: 10;
        position: relative;
        margin-bottom: 0px !important;
        display: inline !important;
    }
    .ppv_background_image {
        max-width: 85% !important;
    }
    .ppv-video-content {
        width: 79.9% !important;
    }
    .ppv-choose-user-type {
        padding: 0 15px;
    }
    .ppv-bottom-margin {
        margin-bottom: 650px;
    }
    .ppv-purchase-your-way {
        padding: 0 10px;
    }
    .no-bg-yellow-right {
        float: right;
    }
    .payment-option-boxes {
        margin-bottom: 15px;
    }
    .detail-bg--clear {
        z-index: -1;
        height: 100vh;
        max-height: 50vh !important;
        background-color: #333;
        background-size: cover;
        -webkit-mask-position: 0 0;
        -webkit-mask-size: 100% 100%;
        -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0, 0, 0, 1)), color-stop(.1, rgba(0, 0, 0, 1)), color-stop(0.3, rgba(0, 0, 0, 0.1)), color-stop(1, transparent));
    }
    .payment-container {
        margin-top: 50px !important;
    }
    ul.tabs li.current {
        font-size: 18px !important;
        margin-bottom: 0px !important;
    }
    ul.tabs li {
        font-size: 18px !important;
        margin-bottom: 0px !important;
    }
    .btn-payment-subn-type {
        margin-top: 15px !important;
    }
    .search-logo {
        top: 5px;
        font-size: 25px;
    }
    .details-tile-content {
        margin-bottom: 0;
    }
    .series-play-button {
        padding: 8px 15px;
        font-size: 14px;
        border-radius: 50px;
        text-transform: none;
        background-color: var(--button-color);
        border: 2px solid var(--button-color);
        margin: 30px 10px;
        outline: 0px !important;
        width: max-content;
        color: var(--button-font-color);
        font-weight: 600;
    }
    .series-info {
        width: 250px;
        margin: auto;
    }
    .paid-tag {
        right: 3px;
    }
    .question-section {
        padding: 20px;
        color: var(--font-color);
    }
    .polling-bg-card {
        margin-bottom: 20px;
        height: 500px !important;
        width: 100% !important;
        margin: auto;
    }
    .avatar-image {
        width: 150px;
        height: 150px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        background-size: cover;
        border-radius: 100%;
        cursor: pointer;
    }
    .avatar-image:hover {
        width: 140px;
        height: 140px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        background-size: cover;
        border-radius: 100%;
        cursor: pointer;
    }
    .subscription-card {
        width: 460px;
        padding: 20px 0px;
        margin: 0px auto;
    }
}


/* Tablets */

@media (min-width: 768px) and (max-width: 993px) {
    .basic-title {
        color: var(--card-font-color);
        font-size: 2.2em;
        margin: 0 0 .5em;
        line-height: 1em;
        font-weight: 600;
    }
    .basic-para {
        letter-spacing: .2px;
        padding: 0 10px;
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 20px;
        margin-left: -1%;
        color: var(--card-font-color);
    }
    .form-signup-google .btn {
        background-color: #dc4e41;
        float: left;
        font-size: 12px;
        text-transform: inherit;
        width: auto;
        border: medium none;
        border-radius: 4px;
        color: #fff;
        display: inline-block;
        font-weight: normal;
        line-height: 40px;
        padding: 0 20px;
        position: relative;
        z-index: 1;
        margin-top: 15px;
    }
    .form-signup-facebook .btn {
        background-color: #3b5998;
        float: right;
        font-size: 12px;
        text-transform: inherit;
        width: auto;
        border: medium none;
        border-radius: 4px;
        color: #fff;
        display: inline-block;
        font-weight: normal;
        line-height: 40px;
        padding: 0 20px;
        position: relative;
        z-index: 1;
        margin-top: 15px;
    }
    .pull-left1 {
        float: left;
    }
    .pull-right1 {
        float: right;
    }
    .highlight-img {
        background-position: center 10%;
        width: 100% !important;
        margin-left: 0%;
    }
    .header_section {
        padding: 0 90px;
        width: 100%;
    }
    .navbar-default {
        border-radius: 0;
        border: none;
        margin-bottom: 0;
        width: 100vw;
    }
    .centered-div {
        transform: scale(1);
        opacity: 1;
        transition-duration: 450ms;
        transition-delay: 200ms;
        width: 75%;
        margin: auto;
        padding: 70px 30px;
    }
    .embed-top {
        margin-top: 100px;
    }
    .height {
        height: 280px;
    }
    .start-btn {
        background-color: var(--button-color);
        border-radius: 50px;
        padding: 10px 20px;
        font-size: 18px;
        color: var(--button-font-color) !important;
        font-weight: 700;
    }
    .single-tab-content,
    .single-tab-content {
        margin: 60px 1%;
        padding-left: 15px;
    }
    .single-subcategory {
        color: var(--bg-font-color);
        font-weight: 700;
        margin-top: 30px;
        margin-left: 1%
    }
    .playlist-title {
        font-size: 14px;
        color: var(--bg-font-color);
        font-weight: 700;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-top: 10px;
        margin-bottom: 2%;
        width: calc(100% - 135px);
    }
    .morelike-box-height {
        height: 170px;
    }
    .video-img {
        height: 170px;
        width: 90%;
    }
    .details-page {
        margin-top: 130px !important;
        margin: 0% 5%;
        padding: 10px;
    }
    .limit {
        max-width: 80%;
        height: 20px;
        color: var(--mm-font-color);
        background-color: transparent;
        overflow-x: auto;
    }
    /* ::-webkit-scrollbar {
        display: none;
    } */
    .leftArrow {
        margin-top: -6px;
        margin-right: -3%;
        margin-left: 3%;
        color: var(--button-color) !important;
        font-size: 24px;
    }
    .rightArrow {
        margin-top: -6px;
        margin-right: 2%;
        color: var(--button-color) !important;
        font-size: 24px;
    }
    .sliderSection {
        display: inline-flex;
        width: 84%;
        margin-top: 2%;
        margin-bottom: 5px;
    }
    .subscribe {
        margin-top: 6px;
        margin-left: 20px;
        color: var(--button-font-color);
        text-align: right;
        font-weight: 700;
        background-color: var(--button-color);
        border: 1px solid var(--button-color);
        letter-spacing: 1px;
        border-radius: 50px;
    }
    .highlight {
        position: relative;
        margin-bottom: 16px;
    }
    .account-layout-inner {
        margin-top: 5%;
    }
    .login-form-inner {
        max-width: 500px;
        margin: 0 auto;
        padding: 40px;
        margin-top: 9%;
    }
    .spark-style {
        margin-left: 4%;
        margin-left: 3% !important;
    }
    .device-join-txt {
        font-size: 17px;
        font-weight: 100;
    }
    .footer-logo {
        margin-top: -5px;
        width: 120px;
    }
    .table-borde-black {
        width: 96.5%;
    }
    .profile-entry input[type="text"],
    .profile-entry input[type="email"] {
        font-size: 1em;
    }
    .profile-button a,
    .profile-button span {
        font-size: 12px !important;
        padding: 12px !important;
    }
    .overview-details {
        top: 0px;
        width: 50%;
    }
    .linear-gradient {
        display: none !important;
    }
    .tile-details-left-space {
        width: 100%;
    }
    .play-icon {
        margin-left: 0%;
    }
    .show-content-category-left {
        margin-left: -30px !important;
        padding-left: 0px !important;
    }
    .fa-chevron-circle-left {
        display: none;
    }
    .fa-chevron-circle-right {
        display: none;
    }
    .payment-boxes-padding {
        padding-left: 10px;
        padding-right: 10px;
    }
    .tab {
        width: 30% !important;
    }
    .tabcontent {
        width: 70% !important;
        padding-left: 0px !important;
    }
    .series-tab-content {
        padding-left: 0px !important;
    }
    .series-single-episode-desc {
        padding: 0px;
    }
    .series-video-description {
        margin-left: 0px !important;
    }
    .overview-details-hide-xs {
        display: none !important;
    }
    .home-box-title {
        color: var(--bg-font-color);
        padding: 10px;
        margin: 10% 0 5%;
    }
    .play-icon-outer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 50;
    }
    .card-box {
        max-height: 480px !important;
    }
    .chat {
        height: auto!important;
    }
    .live-transition-class {
        display: none;
    }
    .content-wrapper {
        margin: 100px 0 40px;
    }
    .series-video-page-grid {
        overflow: hidden;
        padding-top: 25px !important;
        z-index: 10;
        position: relative;
    }
    .ppv_background_image {
        max-width: 85% !important;
    }
    .ppv-video-content {
        width: 80.9% !important;
    }
    .ppv-choose-user-type {
        padding: 0 0px;
    }
    .ppv-page-title {
        text-align: left;
        margin: 45px 20px 10px;
    }
    .ppv-bottom-margin {
        margin-bottom: 170px;
    }
    .ppv-box-padding {
        padding: 20px 25px !important;
    }
    .detail-bg--clear,
    .detail-bg--layer2 {
        width: 100%;
        background-position: top;
        background-repeat: no-repeat;
    }
    .detail-bg--clear {
        z-index: -1;
        height: 100vh;
        max-height: 50vh !important;
        background-color: #333;
        background-size: cover;
        -webkit-mask-position: 0 0;
        -webkit-mask-size: 100% 100%;
        -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0, 0, 0, 1)), color-stop(.1, rgba(0, 0, 0, 0.7)), color-stop(.4, rgba(0, 0, 0, 0.1)), color-stop(1, transparent));
    }
    .series-video-page-grid {
        overflow: hidden;
        padding-top: 135px !important;
        z-index: 10;
        position: relative;
    }
    .payment-container {
        margin-top: 50px !important;
    }
    ul.tabs li.current {
        font-size: 18px !important;
    }
    ul.tabs li {
        font-size: 18px !important;
    }
    .details-video-details {
        max-width: 302px;
        margin: auto;
        left: -18.5px;
    }
    .series-slider .slick-prev,
    .series-slider .slick-next {
        height: calc(100% - 30px) !important;
        margin-top: 5px;
    }
    .highlight>.slick-slider>.slick-prev {
        border-radius: 100px;
        height: 60px !important;
        padding: 2px 10px 8px 5px;
        width: 60px;
        margin-left: 5px;
        top: calc(((100vw - 30px)*.35*.56) + 60px) !important;
    }
    .highlight>.slick-slider>.slick-next {
        border-radius: 100px;
        height: 60px !important;
        padding: 2px 5px 8px 10px;
        width: 60px;
        margin-right: 5px;
        top: calc(((100vw - 30px)*.35*.56) + 60px) !important;
    }
    .signin {
        float: right;
        margin-top: 10px;
        color: var(--button-font-color) !important;
        background: var(--button-color);
        border-radius: 50px;
    }
    .subscription-tag {
        min-height: 50px;
        background: linear-gradient(to right, #b82020, #3356ff);
        text-align: center;
        margin: 10px;
        padding: 10px;
        font-size: 20px;
        margin: 1% 2% 20px;
    }
    .question-section {
        padding: 20px;
        color: var(--font-color);
    }
    .polling-bg-card {
        margin-bottom: 20px;
        height: 500px !important;
        width: 100% !important;
        margin: auto;
    }
    .subscription-card {
        width: 50%;
        padding: 30px;
    }
    .header_section {
        padding: 0 50px !important;
    }
    /* .search-field {
        width: 23px !important;
    } */
    .search-field:focus {
        width: 270px !important;
    }
    .search-field:focus .home-logo {
        display: none !important;
    }
    .highlight .banner-info {
        left: 50px !important;
    }
    .post,
    #related-content,
    .silck-btn-align {
        padding: 0 50px !important;
    }
    .box-title {
        width: 100%;
        position: relative;
    }
    .video-slider .slick-next,
    .video-slider .slick-next.slick-disabled {
        right: -8% !important;
    }
    .video-slider .slick-prev,
    .video-slider .slick-prev.slick-disabled {
        left: -7% !important;
    }
    #slick-genre-102 .slick-prev,
    #slick-genre-102 .slick-prev.slick-disabled {
        left: -7% !important;
    }
    #slick-genre-102 .slick-next,
    #slick-genre-102 .slick-next.slick-disabled {
        right: 0% !important;
    }
    footer #footer-container {
        padding: 0 90px !important;
    }
    #category-page #genre-align {
        padding: 40px 0px 40px 50px !important;
    }
    .bodyParent {
        /* overflow-x: hidden; */
    }
    .live-bg-img .channels-page .video-box-row .details-series-tile,
    .bg-cards,
    #series-page #episode-section #card-outer #card-format #card-item {
        margin: 0 auto;
    }
    #content-detail #content-bg #detail {
        /* margin-left: 30px !important; */
    }
    #episode-description {
        width: 100% !important;
    }
    #series-page #episode-section #card-outer {
        padding-left: 0px !important;
    }
}


/* Medium Devices, */

@media (min-width: 992px) and (max-width: 1199px) {
    .basic-title {
        color: var(--card-font-color);
        font-size: 2.7em;
        margin: 0 0 .5em;
        line-height: 1em;
        font-weight: 500;
    }
    .basic-para {
        letter-spacing: .2px;
        padding: 0 10px;
        font-size: 22px;
        line-height: 23px;
        margin-bottom: 20px;
        margin-left: -1%;
        color: var(--card-font-color);
    }
    .form-signup-google .btn {
        background-color: #dc4e41;
        float: left;
        font-size: 12px;
        text-transform: inherit;
        width: auto;
        border: medium none;
        border-radius: 4px;
        color: #fff;
        display: inline-block;
        font-weight: normal;
        line-height: 40px;
        padding: 0 20px;
        position: relative;
        z-index: 1;
        margin-top: 15px;
    }
    .form-signup-facebook .btn {
        background-color: #3b5998;
        float: right;
        font-size: 12px;
        text-transform: inherit;
        width: auto;
        border: medium none;
        border-radius: 4px;
        color: #fff;
        display: inline-block;
        font-weight: normal;
        line-height: 40px;
        padding: 0 20px;
        position: relative;
        z-index: 1;
        margin-top: 15px;
    }
    .pull-left1 {
        float: left;
    }
    .pull-right1 {
        float: right;
    }
    .highlight-img {
        background-position: center 10%;
        width: 100%;
        height: calc((100vw)*.5*0.56);
    }
    .highlight {
        position: relative;
        margin-top: 0px;
    }
    .navbar-brand {
        width: 115px;
    }
    .header_section {
        padding: 0 90px;
        width: 100%;
    }
    .centered-div {
        transform: scale(1);
        opacity: 1;
        transition-duration: 450ms;
        transition-delay: 200ms;
        width: 60%;
        margin: auto;
        padding: 70px 30px;
    }
    .embed-top {
        margin-top: 100px;
    }
    .height {
        height: 205px;
    }
    .height1 {
        height: 205px;
    }
    .start-btn {
        background-color: var(--button-color);
        border-radius: 50px;
        padding: 10px 20px;
        font-size: 18px;
        color: var(--button-font-color) !important;
        font-weight: 700;
    }
    .single-tab-content,
    .single-tab-content1 {
        margin: 60px 7.1%;
        padding-left: 10px;
    }
    .details-page {
        margin-top: 80px !important;
        margin: 0% 5%;
        padding: 1%;
    }
    .login-form-inner {
        max-width: 500px;
        margin: 0 auto;
        padding: 40px;
    }
    .category-page {
        margin-top: 146px !important;
        margin: 2% 3% 19% 3%;
    }
    .single-subcategory {
        color: var(--bg-font-color);
        font-weight: 700;
        margin-top: 30px;
    }
    .spark-style {
        margin-left: 8%;
    }
    .footer-logo {
        margin-top: -5px;
        width: 150px;
    }
    .table-borde-black {
        width: 96.5%;
    }
    .profile-button a,
    .profile-button span {
        font-size: 12px !important;
        padding: 12px !important;
    }
    .tile-details-left-space {
        left: 0px;
        width: 100%;
    }
    .play-icon {
        margin-left: 300%;
    }
    .fa-chevron-circle-left {
        display: none;
    }
    .fa-chevron-circle-right {
        display: none;
    }
    .overview-details {
        width: 50%;
        padding: calc((100vw)*.5*0.56*0.1) 0 0 calc((100vw)*.5*0.56*0.1);
    }
    .linear-gradient {
        width: 50%;
        left: 50%;
    }
    .user-comment-section {
        color: var(--bg-font-color);
        padding-left: 35px;
    }
    .morelike-box-height {
        height: 130px;
    }
    .video-img {
        height: 130px;
        width: 100%;
    }
    .content-tit {
        font-size: 19px;
    }
    .ppv_background_image {
        max-width: 85% !important;
    }
    .ppv-video-content {
        width: 81.9% !important;
    }
    .ppv-choose-user-type {
        padding: 0 10px;
    }
    .ppv-page-title {
        text-align: left;
        margin: 45px 70px 10px;
    }
    .ppv-bottom-margin {
        margin-bottom: 170px;
    }
    .overview-des {
        max-height: 70px;
    }
    .series-slider .slick-prev,
    .series-slider .slick-next {
        height: calc(100% - 30px) !important;
        margin-top: 5px;
    }
    .navbar-default {
        border-radius: 0;
        border: none;
        margin-bottom: 0;
        width: 100vw;
    }
    .subscription-card {
        width: 50%;
        padding: 30px;
    }
    .search-field:focus {
        width: 270px !important;
    }
    .highlight .banner-info {
        left: 90px !important;
    }
    .post,
    #related-content,
    .silck-btn-align {
        padding: 0 90px !important;
    }
    .box-title {
        width: 100%;
        position: relative;
    }
    .video-slider .slick-next,
    .video-slider .slick-next.slick-disabled {
        right: -12% !important;
    }
    .video-slider .slick-prev,
    .video-slider .slick-prev.slick-disabled {
        left: -11% !important;
    }
    #slick-genre-102 .slick-prev,
    #slick-genre-102 .slick-prev.slick-disabled {
        left: -10% !important;
    }
    #slick-genre-102 .slick-next,
    #slick-genre-102 .slick-next.slick-disabled {
        right: 0% !important;
    }
    #category-page #genre-align {
        padding: 40px 0px 40px 90px !important;
    }
    .bodyParent {
        overflow-x: hidden;
    }
    .live-bg-img .channels-page .video-box-row .details-series-tile,
    .bg-cards,
    #series-page #episode-section #card-outer #card-format #card-item {
        margin: 0 auto;
    }
    #content-detail #content-bg #detail {
        /* margin-left: 50px !important; */
    }
    #sub-category-page #card-align,
    #series-page #episode-section #card-outer #card-format,
    #wishlist-page #content #card-align {
        grid-template-columns: repeat(2, 1fr) !important;
    }
    #episode-description {
        width: 100% !important;
    }
    #series-page #episode-section #card-outer {
        padding-left: 0px !important;
    }
    .seasons-bg-img {
        width: auto !important;
    }
}

@media (min-width: 1200px) and (max-width: 1369px) {
    .basic-title {
        color: var(--card-font-color);
        margin: 0 0 .2em;
        line-height: 1em;
        font-weight: 500;
        display: inline-block;
        width: 520px;
    }
    .basic-para {
        letter-spacing: .2px;
        padding: 0 10px;
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 1%;
        color: var(--card-font-color);
    }
    .form-signup-google .btn {
        background-color: #dc4e41;
        float: left;
        font-size: 12px;
        text-transform: inherit;
        width: auto;
        border: medium none;
        border-radius: 4px;
        color: #fff;
        display: inline-block;
        font-weight: normal;
        line-height: 40px;
        padding: 0 20px;
        position: relative;
        z-index: 1;
        margin-top: 15px;
    }
    .form-signup-facebook .btn {
        background-color: #3b5998;
        float: right;
        font-size: 12px;
        text-transform: inherit;
        width: auto;
        border: medium none;
        border-radius: 4px;
        color: #fff;
        display: inline-block;
        font-weight: normal;
        line-height: 40px;
        padding: 0 20px;
        position: relative;
        z-index: 1;
        margin-top: 15px;
    }
    .pull-left1 {
        float: left;
    }
    .pull-right1 {
        float: right;
    }
    .highlight-img {
        background-position: center 10%;
        width: 100%;
        height: calc((100vw)*.5*0.56);
    }
    .highlight {
        position: relative;
        min-height: 500px;
        margin-top: 0px;
    }
    .header_section {
        padding: 0 130px;
        width: 100%;
    }
    .navbar-default {
        border-radius: 0;
        border: none;
        margin-bottom: 0;
    }
    .centered-div {
        transform: scale(1);
        opacity: 1;
        transition-duration: 450ms;
        transition-delay: 200ms;
        width: 45%;
        margin: auto;
        padding: 70px 30px;
    }
    .profile-entry input[type="text"],
    .profile-entry input[type="email"] {
        width: 20em !important;
    }
    .embed-top {
        margin-top: 100px;
    }
    .height {
        height: 150px;
    }
    .height1 {
        height: 210px;
    }
    .start-btn {
        background-color: var(--button-color);
        border-radius: 50px;
        padding: 10px 20px;
        font-size: 18px;
        color: var(--button-font-color) !important;
        font-weight: 700;
    }
    .details-page {
        margin-top: 80px !important;
        margin: 6% 10px;
        padding: 1%;
    }
    .details-video-box-row {
        margin-bottom: 10px !important;
    }
    .single-subcategory {
        color: var(--bg-font-color);
        font-weight: 700;
        margin-top: 32px;
    }
    .spark-style {
        margin-left: 8%;
    }
    .single-tab-content,
    .single-tab-content1 {
        margin: 60px 7.1%;
        padding-left: 5px;
    }
    .table-borde-black {
        width: 95%;
    }
    .profile-button a,
    .profile-button span {
        font-size: 12px !important;
        padding: 12px !important;
    }
    .overview-details {
        width: 50%;
    }
    .linear-gradient {
        width: 50%;
        left: 50%;
    }
    .play-icon {
        margin-left: 400%;
    }
    .fa-chevron-circle-left {
        display: none;
    }
    .fa-chevron-circle-right {
        display: none;
    }
    .morelike-box-height {
        height: 130px;
    }
    .video-img {
        height: 130px;
        width: 100%;
    }
    .content-tit {
        font-size: 21px;
    }
    .ppv-video-content {
        width: 70.9%;
    }
    .ppv-choose-user-type {
        padding: 0 10px;
    }
    .ppv-page-title {
        text-align: left;
        margin: 45px 70px 10px;
    }
    .ppv-bottom-margin {
        margin-bottom: 300px;
    }
    .ppv-invoice-video-image {
        height: 100px;
        width: 100px;
    }
    .ppv-box-padding {
        padding: 40px!important;
    }
    .no-padding-payment-dtl {
        font-size: 16px;
    }
    .btn-payment-subn-type {
        font-size: 16px;
    }
    ul.tabs li.current {
        font-size: 20px !important;
    }
    ul.tabs li {
        font-size: 20px !important;
    }
    .btn-payment-subn-type {
        font-size: 16px;
    }
    .play-icon {
        margin-left: 400%;
    }
    .ppv_background_image {
        max-width: 73% !important;
        margin: auto;
    }
    .video-slider .slick-prev,
    .video-slider .slick-prev.slick-disabled {
        left: -13% !important;
    }
    .video-slider .slick-next,
    .video-slider .slick-next.slick-disabled {
        right: 0% !important;
    }
    #slick-genre-102 .slick-prev,
    #slick-genre-102 .slick-prev.slick-disabled {
        left: -12% !important;
    }
    #slick-genre-102 .slick-next,
    #slick-genre-102 .slick-next.slick-disabled {
        right: 0% !important;
    }
}

@media (min-width: 1369px) and (max-width: 1600px) {

    .video-slider .slick-prev,
    .video-slider .slick-prev.slick-disabled {
        left: -11% !important;
    }
    .video-slider .slick-next,
    .video-slider .slick-next.slick-disabled {
        right: 0% !important;
    }
    #slick-genre-102 .slick-prev,
    #slick-genre-102 .slick-prev.slick-disabled {
        left: -10.2% !important;
    }
    #slick-genre-102 .slick-next,
    #slick-genre-102 .slick-next.slick-disabled {
        right: 0% !important;
    }
}

@media (min-width: 1600px) and (max-width: 2328px) {
    .video-slider .slick-prev,
    .video-slider .slick-prev.slick-disabled {
        left: -130px !important;
    }
    .video-slider .slick-next,
    .video-slider .slick-next.slick-disabled {
        right: 0% !important;
    }
    #slick-genre-102 .slick-prev,
    #slick-genre-102 .slick-prev.slick-disabled {
        left: -130px !important;
    }
    #slick-genre-102 .slick-next,
    #slick-genre-102 .slick-next.slick-disabled {
        right: 0% !important;
    }
}

@media only screen and (min-width: 2328px) {

    .video-slider .slick-prev,
    .video-slider .slick-prev.slick-disabled {
        left: -6% !important;
    }
    .video-slider .slick-next,
    .video-slider .slick-next.slick-disabled {
        right: 0% !important;
    }
    #slick-genre-102 .slick-prev,
    #slick-genre-102 .slick-prev.slick-disabled {
        left: -6% !important;
    }
    #slick-genre-102 .slick-next,
    #slick-genre-102 .slick-next.slick-disabled {
        right: 0% !important;
    }
}


/* Desktops */

@media only screen and (min-width: 1370px) {
    .basic-title {
        color: var(--card-font-color);
        display: inline-block;
        margin: 0 0 .2em;
        line-height: 1em;
        font-weight: 500;
        width: 515px;
    }
    .basic-para {
        letter-spacing: .2px;
        padding: 0 10px;
        font-size: 22px;
        line-height: 23px;
        margin-bottom: 30px;
        margin-top: 1%;
        color: var(--card-font-color);
    }
    .form-signup-google .btn {
        background-color: #dc4e41;
        float: left;
        font-size: 12px;
        text-transform: inherit;
        width: auto;
        border: medium none;
        border-radius: 4px;
        color: #fff;
        display: inline-block;
        font-weight: normal;
        line-height: 40px;
        padding: 0 20px;
        position: relative;
        z-index: 1;
        margin-top: 15px;
    }
    .form-signup-facebook .btn {
        background-color: #3b5998;
        float: right;
        font-size: 12px;
        text-transform: inherit;
        width: auto;
        border: medium none;
        border-radius: 4px;
        color: #fff;
        display: inline-block;
        font-weight: normal;
        line-height: 40px;
        padding: 0 20px;
        position: relative;
        z-index: 1;
        margin-top: 15px;
    }
    .pull-left1 {
        float: left;
    }
    .pull-right1 {
        float: right;
    }
    .highlight-img {
        height: calc((100vw)*.5*0.56);
        background-position: center 10%;
    }
    .highlight {
        position: relative;
        min-height: calc(100% - 25%);
    }
    .header_section {
        padding: 0 130px;
        width: 100%;
    }
    .navbar-default {
        border-radius: 0;
        border: none;
        margin-bottom: 0;
    }
    .centered-div {
        transform: scale(1);
        opacity: 1;
        transition-duration: 450ms;
        transition-delay: 200ms;
        width: 50%;
        margin: auto;
        padding: 50px;
    }
    .embed-top {
        margin-top: 100px;
    }
    .height {
        height: 180px;
    }
    .start-btn {
        background-color: var(--button-color);
        border-radius: 50px;
        padding: 10px 20px;
        font-size: 18px;
        color: var(--button-font-color) !important;
        font-weight: 700;
    }
    .details-video-box-row {
        margin-bottom: 10px !important;
    }
    .single-tab-content,
    .single-tab-content1 {
        padding-left: 10px;
        margin: 60px 7%;
    }
    .spark-style {
        margin-left: 8%;
    }
    .table-borde-black {
        width: 96%;
    }
    .fa-chevron-circle-left {
        display: none;
    }
    .fa-chevron-circle-right {
        display: none;
    }
    .navbar-default {
        border-radius: 0;
        border: none;
        margin-bottom: 0;
        /* padding-top: 30px; */
    }
    .morelike-box-height {
        height: 150px;
    }
    .video-img {
        height: 150px;
        width: 90%;
    }
    .content-tit {
        font-size: 30px;
    }
    .ppv-video-content {
        width: 63.9%;
    }
    .ppv-choose-user-type {
        padding: 0 110px;
    }
    .ppv-page-title {
        text-align: left;
        margin: 45px 145px 10px;
    }
    .ppv-invoice-video-image {
        height: 130px;
        width: 130px;
    }
    .no-padding-payment-dtl {
        font-size: 16px;
    }
    .btn-payment-subn-type {
        font-size: 16px;
    }
    .details-page {
        max-width: 1300px;
        margin: auto;
    }
}

@media screen and (max-width:360px) {
    .height {
        height: 315px;
    }
    .limit {
        max-width: 62%;
        height: 20px;
        color: var(--mm-font-color);
        background-color: transparent;
        overflow-x: auto;
    }
    /* ::-webkit-scrollbar {
        display: none;
    } */
    .leftArrow {
        margin-top: -8px;
        margin-right: 3%;
        margin-left: 3%;
        color: var(--button-color) !important;
        font-size: 27px;
    }
    .rightArrow {
        margin-top: -8px;
        margin-right: 2%;
        color: var(--button-color) !important;
        font-size: 27px;
    }
    .sliderSection {
        display: inline-flex;
        margin-top: 0%;
        width: 70%;
    }
    .slick-prev {
        left: 0;
        display: none !important;
    }
    .slick-next {
        right: 0;
        display: none !important;
    }
    .subscribe {
        margin-top: -3%;
        margin-left: -60px;
        color: var(--button-font-color);
        text-align: right;
        font-weight: 700;
        background-color: var(--button-color);
        border: 1px solid var(--button-color);
    }
    .details-page {
        margin-top: 110px !important;
        margin: 0;
        padding: 10px;
    }
    .home-box-title {
        color: var(--bg-font-color);
        padding: 10px;
        margin: 5% -2% 0px;
    }
    .video-box-row {
        overflow-x: hidden;
        white-space: nowrap !important;
        padding-bottom: 5px;
        overflow-y: hidden;
    }
    .details-video-box-outer {
        margin-right: 2%;
        margin-left: 2%;
    }
    .nav-tabs {
        border-bottom: 1px solid #787878;
    }
    .single-subcategory {
        color: var(--bg-font-color);
        font-weight: 700;
        margin-top: 8%;
    }
    .playlist-title {
        font-size: 14px;
        color: var(--bg-font-color);
        font-weight: 700;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 2%;
        width: 95%;
        max-width: 95% !important;
    }
    .home_top_class {
        margin: 2% 3% 0% 3%;
    }
    .category-page {
        margin-top: 110px !important;
        margin: 2% 3% 38% 3%;
    }
    .profile-button {
        display: block;
        margin: 2em 0 1em 0;
        font-size: 1em !important;
    }
    #page_preloader>img {
        display: block;
        position: absolute;
        left: 48%;
        top: 50%;
        margin: -24px 0 0 -24px;
        width: 20% !important;
        height: auto
    }
    #before_loader>img {
        display: block;
        position: absolute;
        left: 48%;
        top: 50%;
        margin: -24px 0 0 -24px;
        width: 20%;
        height: auto
    }
    .black-btn {
        background-color: var(--button-color);
        border-color: var(--button-color);
        color: var(--button-font-color);
        position: absolute;
    }
    .more-profile>li>a {
        margin-bottom: 15px;
        display: inline-block;
    }
    .dropdown-menu li>a {
        color: var(--mm-font-color);
        margin-bottom: 15px;
        display: block;
        font-size: 15px;
        font-weight: 550;
    }
    .account-section-link {
        color: #0080FF;
        font-size: 16px;
        display: inline-block;
        margin-bottom: 3%
    }
    .add-to-wl>img {
        width: 25px !important;
    }
    .social-btn-like>i {
        font-size: 12px;
    }
    .video-views {
        font-size: 12px !important;
    }
    .social-btn-like {
        margin: 10px 4px !important;
        padding: 8px !important;
    }
    .ads {
        margin-top: 7%;
        overflow: scroll;
    }
    .table-borde-black {
        width: 90%;
    }
    .overview-details-hide-xs {
        display: none !important;
    }
    .highlight {
        position: relative;
        height: 250px;
        margin: 0px 0px 0px;
    }
    .tile-details-left-space {
        left: 5px;
        width: 97%;
        bottom: 0px;
    }
    .details-video-details {
        margin-bottom: 0%;
        width: 93%;
        height: auto;
    }
    .morelike-box-height {
        height: 100px;
    }
    .video-img {
        height: 100px;
        width: 100%;
        margin: 5px auto;
        cursor: pointer;
    }
    .slick-initialized .slick-slide {
        padding: 0px 10px 0px 0px!important;
    }
    .chat {
        height: auto!important;
    }
    .live-transition-class {
        display: none;
    }
    .content-panel {
        padding: 60px 0px 40px;
    }
    .profile-metadata img {
        height: 8vw;
        max-height: 180px;
        max-width: 180px;
        min-height: 60px;
        min-width: 60px;
        width: 8vw;
        border-radius: 100px;
        margin-left: 29px;
    }
    /* .login-form-inner {
        padding: 30px;
    } */
    .ppv_background_image {
        max-width: 90% !important;
    }
    .ppv-video-content {
        width: 90% !important;
    }
    .season-section {
        width: 100%;
    }
    .list-margin {
        margin-right: 2px !important;
    }
    .single-tab-content {
        margin-top: 70px;
    }
    .single-tab-content1 {
        margin-top: 130px;
    }
    .question-section {
        padding: 20px;
        color: var(--font-color);
    }
    .polling-bg-card {
        margin-bottom: 20px;
        height: 500px !important;
        width: 100% !important;
        margin: auto;
    }
}

@media screen and (max-width: 1300px) {
    /* .banner-info {
        margin-left: 5vw;
    }
    .banner-description-card {
        width: 600px;
    } */
    .video-info {
        margin-left: 5vw;
    }
    .description-card {
        width: 600px;
    }
    .channel-info-card .description-card {
        width: auto !important;
    }
    .bodyParent {
        overflow-x: hidden;
    }
}
.epg-slider .slick-prev,
.epg-slider .slick-prev.slick-disabled {
    left: 0% !important;
}
.epg-slider .slick-next,
.epg-slider .slick-next.slick-disabled {
    right: -1% !important;
}

