/*** common component images begin ***/

#bg-line {
    // background-image: url(../assets/#{$app-name}/img/my-list-bg.svg);
    background-image: none;
    height:300px;
    display:none;
}

.device-content{
    details {
      summary::after {
          content: url(../assets/#{$app-name}/img/open-icon.svg);
      } 
  }  
  details[open] summary::after {
      content: url(../assets/#{$app-name}/img/close-icon.svg)
  }
}

.search-count {
    // background-image: var(--home-bg-gradient),url(../assets/#{$app-name}/img/search-bg.svg);
    background-image: var(--home-bg-gradient);
}

.social-btn-separator{
    background:url(../assets/#{$app-name}/img/social-btn-separator.svg);
}

.group-collection-tile-media {
    .img-gradient {
        background-image: url(../assets/#{$app-name}/img/group-collection-card-gradient.png);
    }
}

.arrow-back {
    background: url(../assets/#{$app-name}/img/back.svg) no-repeat;
}

#reset-bg {
    #reset-card {
        background-image: url(../assets/#{$app-name}/img/static_bg_card.svg);
	}
}

.genre-banner-gradient {
    // background-image: url(../assets/#{$app-name}/img/adjustment-layer-genre.svg);
    background: linear-gradient(90deg, var(--card-bg) 20%, rgba(var(--card_color), 0.9) 60%, rgba(25, 25, 25, 0) 80%);
}

.img-sub-profile {
    background: url(../assets/#{$app-name}/img/edit-icon.svg);
}

.dt-check {
    background: url(../assets/#{$app-name}/img/verified-check.svg) no-repeat;
}

.watch-bg-container{
    background-image: url(../assets/#{$app-name}/img/where-watch-bg.svg);
}

#plan-title::before {
    background-image: url(../assets/#{$app-name}/img/cvc-info.svg);
}

.current-subscription #plan-title::before {
	background-image: url(../assets/#{$app-name}/img/left-arrow.svg)!important;
}

.churn-faq-list{
    details{
        summary::after{
            content: url(../assets/#{$app-name}/img/faq-open.svg);
        }
    }
    details[open] summary::after{
        content: url(../assets/#{$app-name}/img/faq-close.svg);
    }
}

#no-content {
    // background-image: url(../assets/#{$app-name}/img/my-list-bg.svg);
    background-image: none;
}
#pin-control {
    // background-image: url(../assets/#{$app-name}/img/my-list-bg.svg);
    background-image: none;
}

.next-data-sec{
    // background: url(../assets/#{$app-name}/img/next-episode-bg.svg) no-repeat 100% 30%;
    background-image: none;
}

#wishlist-page {
	#content {
		// background-image: url(../assets/#{$app-name}/img/my-list-bg.svg);
        background-image: none;
	}
	#no-content {
		// background-image: url(../assets/#{$app-name}/img/my-list-bg.svg);
        background-image: none;
	}
}

.card-bg {
	.feedback-bg {
		// background-image: url(../assets/#{$app-name}/img/static-bg.svg);
        background-image: none;
	}
}

.static-bg {
	// background-image: url(../assets/#{$app-name}/img/static-bg.svg);
    background-image: none;
}

.faq-content{
	summary::after {
		content: url(../assets/#{$app-name}/img/faq-open.svg);
	}
	details[open] summary::after {
		content: url(../assets/#{$app-name}/img/close-icon.svg);
	}
}

#video-bglines{
    // background: url(../assets/#{$app-name}/img/player_bg.svg) no-repeat;
    background-image: none;
}

.episode-page {
	// background: url(../assets/#{$app-name}/img/seasons-bg.svg) no-repeat 80% 0%;
    background-image: none;
}

.close:before {
	content: url(../assets/#{$app-name}/img/episode-close.svg);
}

.index-head {
    // &:after {
    //     background-image: url(../assets/#{$app-name}/img/after-triangle.svg);
    // }
    // &:before {
    //     background-image: url(../assets/#{$app-name}/img/before-triangle.svg);
    // }
}


#arrow-img {
    content: url(../assets/#{$app-name}/img/arrow-down.svg);
}
.open {
    #arrow-img {
        content: url(../assets/#{$app-name}/img/arrow-up.svg) !important;
    }
}
.operator-drop .open #arrow-img{
    content: url(../assets/#{$app-name}/img/arrow-up.svg) !important;
}

#channel-not-available {
	#bg-overlay {
		// background: url(../assets/#{$app-name}/img/no-live-channel-bg.svg) no-repeat;
        background-image: none;
	}
}

#open-pay-dialog{
	.uk-modal-dialog {
		// background-image: url(../assets/#{$app-name}/img/red-lines-opacity0.45.svg);
        background-image: none;
	}
}

#operator-parent {
	#bg-line {
		// background-image: url(../assets/#{$app-name}/img/operator-branding-linesbg.svg);
        background-image: none;
	}
}
#operator-detail {
    // #bg-line {
    //     background-image: url(../assets/#{$app-name}/img/continuous_triangles.svg);
	// }
}

.nav-toggle-btn {
	background-image: url(../assets/#{$app-name}/img/hamburger-menu.svg);
}


.uk-nav-parent-icon>.uk-parent>a::after {
	background-image: url(../assets/#{$app-name}/img/right-chevron.svg) !important;
}

.uk-card-primary.uk-card-body .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-card-secondary.uk-card-body .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-light .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-offcanvas-bar .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-overlay-primary .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-parent-icon>.uk-parent.uk-open>a::after {
	background-image: url(../assets/#{$app-name}/img/arrow-down.svg) !important;
}

.uk-nav {
	.custom-btn {
		background-image: url(../assets/#{$app-name}/img/right-chevron.svg);
	}
	
}

.user-icon {
	background-image: url(../assets/#{$app-name}/img/profile.svg);
}

.ok-icon {
	background-image: url(../assets/#{$app-name}/img/check.svg);
}

.gift-card-icon {
	background-image: url(../assets/#{$app-name}/img/gift-card.svg);
}

.help-icon {
	background-image: url(../assets/#{$app-name}/img/support.svg);
}

.sign-out-icon {
	background-image: url(../assets/#{$app-name}/img/sign-out.svg);
}

.top-container {
	// background-image: url(../assets/#{$app-name}/img/content-description-bg.svg);
    background-image:none;
}

.unsub-home-bg{
	// background-image: url(../assets/#{$app-name}/img/Left.svg), url(../assets/#{$app-name}/img/Right.svg);
    background-image:none;
}

.dt-check {
    background: url(../assets/#{$app-name}/img/verified-check.svg) no-repeat;
}
.edit-icon {    
    background: url(../assets/#{$app-name}/img/account-page-edit.svg) no-repeat;
}

.check-icon {
    background: url(../assets/#{$app-name}/img/check.svg) no-repeat top left;
}

.visa-icon {
    background: url(../assets/#{$app-name}/img/visa.svg) no-repeat top left;
}

.gift-card-icon {
    background: url(../assets/#{$app-name}/img/gift-card.svg) no-repeat top left;
}

.modal-popup-body {
    .modal-popup-logo {
        background: url(../assets/#{$app-name}/img/pay-failed.svg) no-repeat;
    }
}

/*** common component images end ***/


/* gradients start ***/

.seasons-bg-img {
    background-image: linear-gradient( 260deg, rgba(var(--black-sec-rgb), 0.77) 97%, rgba(var(--black-sec-rgb), 0.78) 72%, rgba(var(--black-sec-rgb), 0.95) 13%);
}

#img-bg {
    .title-gradient {
        height: 100%;
        // background-image: url(../assets/#{$app-name}/img/adjustment-layer-genre.svg);
        // background-image: url(../assets/#{$app-name}/img/banner-lines.svg), url(../assets/#{$app-name}/img/adjustment-layer-genre.svg);
        // background:linear-gradient(90deg, rgba(25,25,25,1) 0%, rgba(25,25,25,1) 50%, rgba(25,25,25,0) 100%)!important;
        // background-image: url(../assets/#{$app-name}/img/banner-lines.svg), linear-gradient(90deg, var(--card-bg) 0%, var(--card-bg) 50%, rgba(25,25,25,0) 100%);
        background: linear-gradient(90deg, var(--card-bg) 20%, rgba(var(--card_color), 0.9) 60%, rgba(25, 25, 25, 0) 80%);
    }
}
.ftp-login-box {
    // background: linear-gradient(180deg, #002846 0%, #003D5E 50.6%, #005479 82.1%, #00648C 100%);
    background: rgba(var(--black-sec-rgb), 0.9);
}

.tile-media{
	.tile-img {
        background: linear-gradient(180.18deg, rgba(5, 5, 5, 0.1) 49.89%, var(--bg-color) 99.77%);
	}
}

.tile-media:hover {
	.img-gradient {
		background: linear-gradient(180deg, rgba(5, 5, 5, 0) 27.98%, var(--bg-color) 85%) !important;
	}
}

.normal-tile-media:hover {
    .img-gradient {
        background: linear-gradient(179deg, rgba(var(--black-sec-rgb), 0) 58%, rgba(var(--black-sec-rgb), 0.95) 99%);
    }
}


.series-tile-media {
	.series-gradient {
		background: linear-gradient(180.38deg, rgba(5, 5, 5, 0.1) 49.89%, var(--bg-color) 99.77%);
	}
	.tile-img {
		background: linear-gradient(180.18deg, rgba(5, 5, 5, 0.1) 49.89%, var(--bg-color) 99.77%);
	}
	.series-tile-img {
		background: linear-gradient(180.38deg, rgba(5, 5, 5, 0.1) 49.89%, var(--bg-color) 99.77%), linear-gradient(0deg, rgba(31, 31, 31, 0.24), rgba(31, 31, 31, 0.24));
	}
}

.top-in-region-img-gradient {
    background-image: linear-gradient(179deg, rgba(var(--black-sec-rgb), 0) 58%, rgba(var(--black-sec-rgb), 0.95) 99%);
}

/* gradients end **/