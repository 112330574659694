/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// Import branding (fonts, colors, gradients) stylesheet
@import "tv2z-branding.scss";


@import "corestyles.scss";

// Component specific images 
@import "component-image-styles.scss";



.skin-filmbox {
    .ftp-login-box {
        background: rgba(23,23,24, 0.9) url(../assets/filmbox/img/operator-loginbg.svg);
    }
}

// App specific styles start from here


